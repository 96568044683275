import React, { FC } from "react";
import { Panel } from "..";
import { Button, Form, Input, Select } from "antd";
import { RequestError, TAccount } from "../../../types";
import { accountStatusOptions } from "../../../constants/options";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";

type TAccountEditFormProps = {
  account?: TAccount;
  onSubmit: (values: TAccount) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const AccountEditForm: FC<TAccountEditFormProps> = ({
  account,
  onSubmit,
  isLoading,
  error,
}) => {
  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={account}
      >
        <Form.Item<TAccount>
          label="Статус"
          name="status"
          rules={[
            {
              required: true,
              message: "Введите статус",
            },
          ]}
        >
          <Select options={accountStatusOptions} />
        </Form.Item>
        <Form.Item<TAccount>
          label="Название счета"
          name="name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите название счета",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="Банк"
          name="bank_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите название банка",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="Расчетный счет"
          name="number"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "Расчетный счет должен состоять из цифр",
            },
            {
              required: true,
              whitespace: true,
              message: "Введите расчетный счет",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="Кор. счет"
          name="correspondent"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "Кор. счет должен состоять из цифр",
            },
            {
              required: true,
              whitespace: true,
              message: "Введите кор, счет",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="БИК"
          name="bik"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "БИК должен состоять из цифр",
            },
            {
              required: true,
              whitespace: true,
              message: "Введите БИК",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TAccount>
          label="ОКАТО"
          name="okato"
          rules={[
            {
              pattern: new RegExp(/^\d{1,}$/),
              message: "ОКАТО должен состоять из цифр",
            },
            {
              required: true,
              whitespace: true,
              message: "Введите ОКАТО",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
