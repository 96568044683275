import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../store/store";

import { Wrapper } from "./OffersForBuyer.styles";
import { Typography } from "antd";
import { OffersTable } from "../../common";
import { userSelectors } from "../../../store/user";
import { useNavigate } from "react-router-dom";
import { SearchForm } from "./compoents";
import {
  offersForBuyerGetActions,
  offersForBuyerGetSelectors,
} from "../../../store/offersForBuyer/get";
import { getOffersForBuyer } from "../../../store/offersForBuyer/get/thunk";

export const OffersForBuyer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const { offersForBuyer, totalElements, page, size, isLoading } =
    useAppSelector(offersForBuyerGetSelectors.getState);

  const getOffersArr = useCallback(() => {
    dispatch(getOffersForBuyer());
  }, [dispatch]);

  const onSearch = useCallback(
    (search?: string) => {
      dispatch(offersForBuyerGetActions.setPage(1));
      dispatch(offersForBuyerGetActions.setSearch(search));
      getOffersArr();
    },
    [dispatch, getOffersArr]
  );

  const onPaginationChange = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize === size) {
        dispatch(offersForBuyerGetActions.setPage(page));
      } else {
        dispatch(offersForBuyerGetActions.setPage(1));
        dispatch(offersForBuyerGetActions.setSize(pageSize));
      }
      getOffersArr();
    },
    [dispatch, getOffersArr, size]
  );

  useEffect(() => {
    if (user?.cabinet?.is_seller) {
      navigate("/myoffers");
    }
  }, [navigate, user]);

  useEffect(() => {
    getOffersArr();
  }, [getOffersArr]);

  useEffect(() => {
    return () => {
      dispatch(offersForBuyerGetActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ marginTop: 0 }}>
        Предложения по моим заявкам
      </Typography.Title>

      <SearchForm onSearch={onSearch} isLoading={isLoading} user={user} />

      <OffersTable
        offers={offersForBuyer}
        pagination={{
          current: page,
          onChange: onPaginationChange,
          pageSize: size,
          total: totalElements,
          showSizeChanger: true,
          pageSizeOptions: [20, 50, 100, 200],
        }}
      />
    </Wrapper>
  );
};
