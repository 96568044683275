import { ComponentProps } from "react";
import {  TOfferForSeller } from "../../types";
import { Tag } from "antd";

export const offerStatusIdMapper: Record<
  TOfferForSeller["status_id"],
  {
    shortName: string;
    full_name?: string;
    description?: string;
    color: ComponentProps<typeof Tag>["color"];
  }
> = {
  99: {
    shortName: "Черновик",
    color: "default",
  },
  123: {
    shortName: "На согласовании у менеджера",
    color: "warning",
  },
  101: {
    shortName: "Согласовано",
    color: "processing",
  },
  103: {
    shortName: "Принимает участие в тендере",
    color: "processing",
  },
  102: {
    shortName: "Отклонено",
    color: "error",
  },
  105: {
    shortName: "Принято",
    color: "processing",
  },
  124: {
    shortName: "На согласовании у покупателя",
    color: "processing",
  },
  125: {
    shortName: "В доставке",
    color: "processing",
  },
  126: {
    shortName: "Ожидание оплаты	",
    color: "processing",
  },
  107: {
    shortName: "Отменено",
    color: "error",
  },
  127: {
    shortName: "Завершено",
    color: "success",
  },
  128: {
    shortName: "Завершено неуспешно",
    color: "error",
  },
};
