import React, { FC, useMemo } from "react";
import { Panel } from "..";
import { Button, Form, Input, Select } from "antd";
import { RequestError, TPersonEntity, TProject } from "../../../types";
import { projectRegionIdOptions } from "../../../constants/options";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";

type TProjectEditFormProps = {
  project?: TProject;
  entities: TPersonEntity[];
  onSubmit: (values: TProject) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const ProjectEditForm: FC<TProjectEditFormProps> = ({
  project,
  entities,
  onSubmit,
  isLoading,
  error,
}) => {
  const entitiesOptions = useMemo(
    () =>
      entities.map((entity) => ({
        label: entity?.entity?.short_name,
        value: entity?.entity?.entity_id,
      })),
    [entities]
  );

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmit}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={project}
      >
        <Form.Item<TProject>
          label="Название"
          name="project_name"
          rules={[
            { required: true, whitespace: true, message: "Введите название" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TProject>
          label="Описание проекта"
          name="project_description"
        >
          <Input />
        </Form.Item>
        <Form.Item<TProject>
          label="Юр. лицо"
          name="entity_id"
          rules={[
            {
              required: true,
              message: "Введите юр. лицо",
            },
          ]}
        >
          <Select options={entitiesOptions} />
        </Form.Item>
        <Form.Item<TProject>
          label="Адрес доставки"
          name="delivery_address"
          rules={[
            { required: true, whitespace: true, message: "Введите название" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TProject>
          label="Комментарий для доставки"
          name="delivery_comment"
        >
          <Input />
        </Form.Item>

        <Form.Item<TProject>
          label="Регион"
          name="region_id"
          rules={[
            {
              required: true,
              message: "Введите регион",
            },
          ]}
        >
          <Select options={projectRegionIdOptions} />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
