import React, { useCallback, useEffect } from "react";
import { Panel } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Alert, Button, Form, FormProps, Input, Typography } from "antd";
import {
  TRegistrationRequest,
  TRestorePasswordRequest,
} from "../../../api/unauthorized";
import { Link } from "react-router-dom";
import { CTABlock } from "./RestorePass.styles";
import {
  restorePasswordActions,
  restorePasswordSelectors,
} from "../../../store/user/restorePassword";
import { restorePassword } from "../../../store/user/restorePassword/thunk";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

export const RestorePass = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error, email } = useAppSelector(
    restorePasswordSelectors.getState
  );

  const onRestorePass: FormProps<TRestorePasswordRequest>["onFinish"] =
    useCallback(
      (values: TRestorePasswordRequest) => {
        dispatch(restorePassword(values));
      },
      [dispatch]
    );

  useEffect(() => {
    return () => {
      dispatch(restorePasswordActions.clearState());
    };
  }, [dispatch]);

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {email ? (
        <Alert
          message="Проверьте Ваш почтовый ящик"
          description={`На Вашу почту ${email} отправлено письмо с инструкцией по восстановлению пароля.`}
          type="success"
          showIcon
        />
      ) : (
        <>
          <Typography.Title level={3} style={{ margin: 0 }}>
            Восстановление пароля
          </Typography.Title>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            // style={{ maxWidth: 600 }}
            onFinish={onRestorePass}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelWrap
            colon={false}
          >
            <Form.Item<TRegistrationRequest>
              label="E-mail"
              name="email"
              rules={[
                { required: true, message: "Введите e-mail", type: "email" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Восстановить пароль
              </Button>
            </Form.Item>

            <ErrorAlert error={error} />
          </Form>
          <CTABlock>
            <Link to="/registration">
              <Button type="link">Зарегистрироваться</Button>
            </Link>
            <Link to="/login">
              <Button type="link">Войти</Button>
            </Link>
          </CTABlock>
        </>
      )}
    </Panel>
  );
};
