import { TApplicationApprovement } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const updateApprovement = (
  data: TUpdateApprovementRequest
): RequestPromise<TUpdateApprovementResponse> => {
  return apiRequest.post(`/approvements/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TUpdateApprovementRequest = {
  approvement_comment?: string;
  approvement_id: number;
  approvement_status_id: number;
};

export type TUpdateApprovementResponse = TApplicationApprovement;
