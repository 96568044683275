import React, { useCallback, useEffect, useState } from "react";
import { Panel } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Alert, Button, Form, FormProps, Input, Typography } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setPassword } from "../../../store/user/setPassword/thunk";
import {
  setPasswordActions,
  setPasswordSelectors,
} from "../../../store/user/setPassword";
import { logout } from "../../../store/user/logout/thunk";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

type TSetPasswordForm = {
  password: string;
  passwordConfirm: string;
};

export const EmailPass = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();

  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const { isLoading, error, success } = useAppSelector(
    setPasswordSelectors.getState
  );

  const onSetPassword: FormProps<TSetPasswordForm>["onFinish"] = useCallback(
    (values: TSetPasswordForm) => {
      dispatch(
        setPassword({
          newpassword: values.password,
          email,
          token,
        })
      )
        .unwrap()
        .then(() => {
          return dispatch(logout())
            .unwrap()
            .then(() => navigate("/login"));
        });
    },
    [dispatch, email, navigate, token]
  );

  useEffect(() => {
    const token = searchParams.get("token");
    const email = searchParams.get("email");
    if (!token || !email) {
      navigate("/login");
    } else {
      setToken(token);
      setEmail(email);
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    return () => {
      dispatch(setPasswordActions.clearState());
    };
  }, [dispatch]);

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {success ? (
        <Alert
          message="Пароль установлен"
          description={`Можно переходить к авторизации.`}
          type="success"
          showIcon
        />
      ) : (
        <>
          <Typography.Title level={3} style={{ margin: 0 }}>
            Установите пароль
          </Typography.Title>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            // style={{ maxWidth: 600 }}
            onFinish={onSetPassword}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelWrap
            colon={false}
          >
            <Form.Item<TSetPasswordForm>
              label="Пароль"
              name="password"
              rules={[
                { required: true, message: "Введите пароль" },
                {
                  pattern:
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
                  message: "Пароль недостаточно надежный",
                },
                {
                  whitespace: true,
                  message: "Пароль не должен содержать пробелы",
                },
              ]}
              tooltip="Пароль должен содержать минимум 8 символов, цифру, строчную и заглавную буквы."
            >
              <Input.Password />
            </Form.Item>

            <Form.Item<TSetPasswordForm>
              label="Повторите пароль"
              name="passwordConfirm"
              rules={[
                { required: true, message: "Введите пароль повторно" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error("Введенные пароли не совпадают")
                    );
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Установить пароль
              </Button>
            </Form.Item>

            <ErrorAlert error={error} />
          </Form>
        </>
      )}
    </Panel>
  );
};
