import axios, { AxiosPromise, AxiosRequestConfig } from "axios";

const apiEndpoint = process.env.REACT_APP_API_URL;

export const apiRequest = {
  get: (url: string, config?: AxiosRequestConfig): AxiosPromise =>
    axios.get(`${apiEndpoint}${url}`, config),
  post: (
    url: string,
    body?: Record<string, unknown>,
    config?: AxiosRequestConfig
  ): AxiosPromise => axios.post(`${apiEndpoint}${url}`, body, config),
};

export type RequestPromise<T> = AxiosPromise<T>;
