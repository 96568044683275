import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOfferForBuyerByIdState } from "./types";

import { RequestError, TOfferForBuyer } from "../../../types";

const initialState: TOfferForBuyerByIdState = {
  isLoading: false,
  error: null,
  offerForBuyer: undefined,
};

export const OfferForBuyerByIdSlice = createSlice({
  name: "offersForBuyer/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOfferForBuyer: (state, { payload }: PayloadAction<TOfferForBuyer>) => {
      state.offerForBuyer = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const offerForBuyerByIdActions = OfferForBuyerByIdSlice.actions;
export const offerForBuyerByIdReducer = OfferForBuyerByIdSlice.reducer;

export * as offerForBuyerByIdSelectors from "./selectors";
