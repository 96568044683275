import React, { FC, useMemo } from "react";
import {
  TApplication,
  TApplicationForSeller,
  TApplicationPosition,
  TEntity,
  TPerson,
  TProject,
} from "../../../types";
import { Table, Tag, Tooltip } from "antd";
import { applicationStatusIdMapper } from "../../../utils/mappers";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { toDisplayDateTime } from "../../../utils/dates";
import { Link } from "react-router-dom";
import { ApplicationsTableExpandableRow } from "./components";

type TApplicationsTableProps = {
  applications?: TApplication[] | TApplicationForSeller[];
  projects?: TProject[];
  pagination?: TablePaginationConfig | false;
  forSeller?: boolean;
};

export const ApplicationsTable: FC<TApplicationsTableProps> = ({
  applications,
  projects,
  pagination,
  forSeller,
}) => {
  const columns: ColumnsType<TApplication | TApplicationForSeller> = useMemo(
    () => [
      {
        title: "Имя заявки",
        key: "application_name",
        render: (value: TApplication) => (
          <Link
            to={`${forSeller ? "/availableapplications" : "/applications"}/${
              value?.application_id
            }`}
          >
            {[value?.application_id, value?.application_name].join(" - ")}
          </Link>
        ),
      },
      {
        title: "Статус",
        dataIndex: "application_status_id",
        render: (value: number) => {
          const statusData = applicationStatusIdMapper[value];
          return statusData ? (
            <Tooltip title={statusData?.description}>
              <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
            </Tooltip>
          ) : (
            value
          );
        },
      },
      ...(forSeller
        ? [
            {
              title: "Юр. лицо покупателя",
              dataIndex: "buyer_entity",
              render: (value: TEntity) => value?.short_name,
            },
          ]
        : []),
      ...(!forSeller
        ? [
            {
              title: "Проект",
              dataIndex: "project_id",
              render: (value: number) => {
                return (
                  projects?.find((project) => project?.project_id === value)
                    ?.project_name || value
                );
              },
            },
          ]
        : []),
      ...(!forSeller
        ? [
            {
              title: "Создатель",
              dataIndex: "creator",
              render: (value: TPerson) => value?.full_name,
            },
          ]
        : []),
      {
        title: "Ответственный",
        dataIndex: "responsible",
        render: (value: TPerson) => value?.full_name,
      },
      {
        title: "Кол-во позиций",
        dataIndex: "positions",
        render: (value: TApplicationPosition[]) => value?.length,
      },
      {
        title: "Дата желаемой поставки",
        dataIndex: "application_delivery_due_datetime",
        render: toDisplayDateTime,
      },
    ],
    [forSeller, projects]
  );

  return (
    <Table
      dataSource={applications}
      columns={columns}
      pagination={pagination}
      scroll={{ x: "1400px" }}
      expandable={{
        expandedRowRender: (row) => (
          <ApplicationsTableExpandableRow application={row} />
        ),
      }}
      rowKey={(row) => row?.application_id}
    />
  );
};
