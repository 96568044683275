import { PageableResponse, TApplicationForSeller } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const getApplicationsForSellers = (
  data: TGetApplicationForSellersRequest
): RequestPromise<TGetApplicationForSellersResponse> => {
  return apiRequest.get(`/applications-for-sellers/get`, {
    params: data,
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TGetApplicationForSellersRequest = {
  search?: string;
  size: number;
  page: number;
};

export type TGetApplicationForSellersResponse =
  PageableResponse<TApplicationForSeller>;
