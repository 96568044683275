import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;
`;

export const CTABlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;


  @media (max-width: 576px) {
    flex-direction: column-reverse;
  }
`;
export const ApprovementsBlock = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  justify-content: flex-end;

@media (max-width: 576px) {
  flex-direction: column;
}
`;
