export const countPrice = (
  amount: string | number = 0,
  price: string | number = 0,
  discount: string | number = 0,
  tax: string | number = 0
) => {
  const {
    amountNormalized,
    priceNormalized,
    discountNormalized,
    taxNormalized,
  } = {
    amountNormalized:
      typeof amount === "string" ? Number(amount.replaceAll(",", ".")) : amount,
    priceNormalized:
      typeof price === "string" ? Number(price.replaceAll(",", ".")) : price,
    discountNormalized:
      typeof discount === "string"
        ? Number(discount.replaceAll(",", "."))
        : discount,
    taxNormalized:
      typeof tax === "string" ? Number(tax.replaceAll(",", ".")) : tax,
  };

  return (
    amountNormalized *
    priceNormalized *
    (1 - discountNormalized / 100) *
    (1 + taxNormalized / 100)
  );
};
