import { applicationApprovementTypeIdMapper } from "../../utils/mappers";

export const applicationApprovementTypeIdOptions = Object.keys(
  applicationApprovementTypeIdMapper
)
  .map(Number)
  .map((item) => ({
    value: item,
    label: applicationApprovementTypeIdMapper[item]?.shortName,
  }));
