import { TAccount } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const updateAccount = (
  data: TUpdateAccountRequest
): RequestPromise<TUpdateAccountResponse> => {
  return apiRequest.post(`/accounts/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TUpdateAccountRequest = TAccount;

export type TUpdateAccountResponse = TAccount;
