import React, { FC } from "react";
import { CardButton, TCardButtonProps } from "../CardButton/CardButton";
import { Container } from "./CardButtonsContainer.styles";

type TCardButtonsContainerProps = {
  buttons: TCardButtonProps[];
};

export const CardButtonsContainer: FC<TCardButtonsContainerProps> = ({
  buttons,
}) => {
  return (
    <Container>
      {buttons.map((button) => (
        <CardButton {...button} key={button.to} />
      ))}
    </Container>
  );
};
