import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TPositionState } from "./types";


import { RequestError, TPositionFromDict } from "../../../types";

const initialState: TPositionState = {
  positions: undefined,
  isLoading: false,
  error: null,
};

export const ApplicationPositionsSLice = createSlice({
  name: "application/positions",
  initialState,
  reducers: {
    clearState: () => initialState,
    setPositions: (state, { payload }: PayloadAction<TPositionFromDict[]>) => {
      state.positions = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const applicationPositionsActions = ApplicationPositionsSLice.actions;
export const applicationPositionsReducer = ApplicationPositionsSLice.reducer;

export * as applicationPositionsSelectors from "./selectors";
