import { TApplication } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const updateApplication = (
  data: TUpdateApplicationRequest
): RequestPromise<TUpdateApplicationResponse> => {
  return apiRequest.post(`/applications/update`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TUpdateApplicationRequest = TApplication;

export type TUpdateApplicationResponse = TApplication;
