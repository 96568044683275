import { PayloadAction, combineReducers, createSlice } from "@reduxjs/toolkit";
import { TUserState } from "./types";

import { RequestError, TPerson } from "../../types";

import { loginReducer } from "./login";
import { registerReducer } from "./register";
import { restorePasswordReducer } from "./restorePassword";
import { setPasswordReducer } from "./setPassword";
import { logoutReducer } from "./logout";

const initialState: TUserState = {
  user: undefined,
  isLoading: false,
  error: null,
};

export const UserSLice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearState: () => initialState,
    setUser: (state, { payload }: PayloadAction<TPerson>) => {
      state.user = payload;
    },
    clearUser: (state) => {
      state.user = initialState.user;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const userActions = UserSLice.actions;
export const userStateReducer = UserSLice.reducer;

export const userReducer = combineReducers({
  state: userStateReducer,
  login: loginReducer,
  register: registerReducer,
  restorePassword: restorePasswordReducer,
  setPassword: setPasswordReducer,
  logout: logoutReducer,
});

export * as userSelectors from "./selectors";
