import styled from "@emotion/styled";
import { Layout } from "antd";

const { Content } = Layout;

export const LayoutStyled = styled(Layout)`
  min-height: 100%;
`;

export const ContentStyled = styled(Content)`
  padding: 24px 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
`;
