import React, { FC, useCallback, useMemo, useState } from "react";

import {
  Button,
  Form,
  FormInstance,
  Input,
  Select,
  Spin,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import {
  applicationPositionCategoryIdOptions,
  applicationPositionUnitTypeIdOptions,
} from "../../../../../constants/options";
import { useAppDispatch, useAppSelector } from "../../../../../store/store";
import {
  applicationPositionsActions,
  applicationPositionsSelectors,
} from "../../../../../store/applications/positions";
import { getPositions } from "../../../../../store/applications/positions/thunk";
import { debounce } from "lodash";
import { TApplicationFormValues } from "../../ApplicationEditForm";

type TPositionsSelectorProps = {
  form: FormInstance<TApplicationFormValues>;
};

export const PositionsSelector: FC<TPositionsSelectorProps> = ({ form }) => {
  const dispatch = useAppDispatch();

  const [search, setSearch] = useState("");

  const { positions, isLoading } = useAppSelector(
    applicationPositionsSelectors.getState
  );

  const positionsOptions = useMemo(
    () => [
      ...(search ? [{ label: search, value: search }] : []),
      ...(positions || []).map((position) => ({
        label: position?.resource_name,
        value: position?.resource_name,
      })),
    ],
    [positions, search]
  );

  const onPositionSearch = useCallback(
    (search: string) => {
      setSearch(search);
      search && dispatch(getPositions({ search }));
    },
    [dispatch]
  );

  const onSelectBlur = useCallback(() => {
    setSearch("");
    dispatch(applicationPositionsActions.clearState());
  }, [dispatch]);

  const onPositionChange = useCallback(
    (positionName: string, key: number) => {
      const position = positions?.find(
        (item) => item?.resource_name === positionName
      );
      form.setFieldValue(
        ["positions", key, "unit_type_id"],
        position?.unit_type_id
      );
      form.setFieldValue(
        ["positions", key, "category_id"],
        position?.parent_id
      );
      form.setFieldValue(
        ["positions", key, "resource_code_id"],
        position?.resource_id
      );
    },
    [form, positions]
  );

  const onClearResourceCode = useCallback(
    (key: number) => {
      form.setFieldValue(["positions", key, "resource_code_id"], undefined);
    },
    [form]
  );
  const onUnitAmountChange = useCallback(
    (value: string, key: number) => {
      form.setFieldValue(
        ["positions", key, "unit_amount"],
        value?.replaceAll(".", ",")
      );
    },
    [form]
  );

  return (
    <>
      <Typography.Title level={4}>Позиции заявки</Typography.Title>
      <Form.List
        name="positions"
        rules={[
          {
            validator: async (_, positions) => {
              if (!positions || !positions.length) {
                return Promise.reject(new Error("Добавьте позиции"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => {
          return (
            <>
              <div style={{ overflow: "auto" }}>
                {fields.map(({ key, name, ...restField }, index, self) => (
                  <div
                    key={key}
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "baseline",
                      marginBottom: 24,
                      width: "max-content",
                    }}
                  >
                    <Form.Item
                      {...restField}
                      label={!index ? "Наименование" : undefined}
                      name={[name, "position_name"]}
                      labelCol={{ span: 24 }}
                      style={{  marginBottom: 0, width: 500 }}
                      rules={[
                        {
                          required: true,
                          message: "Введите название",
                        },
                      ]}
                    >
                      <Select
                        options={positionsOptions}
                        onSearch={debounce(onPositionSearch, 500)}
                        onBlur={onSelectBlur}
                        showSearch
                        notFoundContent={
                          isLoading ? <Spin size="small" /> : null
                        }
                        onChange={(value) => onPositionChange(value, key)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={!index ? "Кол-во" : undefined}
                      name={[name, "unit_amount"]}
                      labelCol={{ span: 24 }}
                      style={{  marginBottom: 0, width: 140 }}
                      rules={[
                        {
                          required: true,
                          message: "Введите кол-во",
                        },
                        {
                          pattern: new RegExp(/^\d*([.,]\d+)?$/),
                          message: "Введите корректное число",
                        },
                      ]}
                    >
                      <Input
                        onChange={(value) =>
                          onUnitAmountChange(value?.target?.value, key)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={!index ? "Ед. измерения" : undefined}
                      name={[name, "unit_type_id"]}
                      labelCol={{ span: 24 }}
                      style={{  marginBottom: 0, width: 140 }}
                      rules={[
                        {
                          required: true,
                          message: "Введите ед. изм.",
                        },
                      ]}
                    >
                      <Select
                        options={applicationPositionUnitTypeIdOptions}
                        onChange={() => onClearResourceCode(key)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={!index ? "Товарная категория" : undefined}
                      name={[name, "category_id"]}
                      labelCol={{ span: 24 }}
                      style={{  marginBottom: 0, width: 250 }}
                      rules={[
                        {
                          required: true,
                          message: "Введите товарную категорию",
                        },
                      ]}
                    >
                      <Select
                        options={applicationPositionCategoryIdOptions}
                        showSearch
                        onChange={() => onClearResourceCode(key)}
                      />
                    </Form.Item>
                    <Form.Item
                      {...restField}
                      label={!index ? "Комментарий" : undefined}
                      name={[name, "position_comment"]}
                      labelCol={{ span: 24 }}
                      style={{  marginBottom: 0, width: 200 }}
                    >
                      <Input />
                    </Form.Item>

                    {self?.length > 1 && (
                      <MinusCircleOutlined
                        onClick={() => remove(name)}
                        style={{ alignSelf: "flex-end", marginBottom: 9 }}
                      />
                    )}
                  </div>
                ))}
              </div>
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Добавить позицию
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          );
        }}
      </Form.List>
    </>
  );
};
