import { applicationPositionCategoryIdMapper } from "../../utils/mappers";

export const applicationPositionCategoryIdOptions = Object.keys(
  applicationPositionCategoryIdMapper
)
  .map(Number)
  .map((item) => ({
    value: item,
    label: applicationPositionCategoryIdMapper[item],
  }));
