import { createAsyncThunk } from "@reduxjs/toolkit";
import { entityAddActions } from "./add";
import { entitiesByCabinetIdActions } from "./byCabinetId";
import { entityByIdActions } from "./byId";
import { entityDeleteActions } from "./delete";
import { entityUpdateActions } from "./update";

export const clearEntities = createAsyncThunk<void, void>(
  "entity/clearEntities",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(entityAddActions.clearState());
    dispatch(entitiesByCabinetIdActions.clearState());
    dispatch(entityByIdActions.clearState());
    dispatch(entityDeleteActions.clearState());
    dispatch(entityUpdateActions.clearState());
  }
);
