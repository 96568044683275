import React, { useCallback, useEffect } from "react";
import { CardButtonsContainer, Panel } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Alert, Button, Typography } from "antd";
import { logout } from "../../../store/user/logout/thunk";
import { logoutActions, logoutSelectors } from "../../../store/user/logout";
import {
  CreditCardOutlined,
  ProjectOutlined,
  ShopOutlined,
  SmileOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { userSelectors } from "../../../store/user";
import { restorePassword } from "../../../store/user/restorePassword/thunk";
import {
  restorePasswordActions,
  restorePasswordSelectors,
} from "../../../store/user/restorePassword";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

export const Profile = () => {
  const dispatch = useAppDispatch();

  const { isLoading: logoutIsLoading, error: logoutError } = useAppSelector(
    logoutSelectors.getState
  );
  const {
    isLoading: restoreIsLoading,
    error: restoreError,
    email: restoreEmail,
  } = useAppSelector(restorePasswordSelectors.getState);
  const { user } = useAppSelector(userSelectors.getState);

  const onLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const onChangePass = useCallback(() => {
    dispatch(restorePassword({ email: user?.email || "" }));
  }, [dispatch, user]);

  useEffect(() => {
    return () => {
      dispatch(logoutActions.clearState());
      dispatch(restorePasswordActions.clearState());
    };
  }, [dispatch]);

  return (
    <Panel
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 1,
        gap: "12px",
      }}
    >
      {user?.first_name && (
        <Typography.Title
          level={3}
        >{`Привет, ${user?.first_name}!`}</Typography.Title>
      )}
      <CardButtonsContainer
        buttons={[
          {
            to: "/personal",
            icon: <SmileOutlined />,
            title: "Личная информация",
            gridColumn: 3,
          },
          {
            to: "/companies",
            icon: <ShopOutlined />,
            title: "Мои компании",
          },
          {
            to: "/colleagues",
            icon: <TeamOutlined />,
            title: "Коллеги",
          },
          {
            to: "/projects",
            icon: <ProjectOutlined />,
            title: "Проекты",
          },
          {
            to: "/accounts",
            icon: <CreditCardOutlined />,
            title: "Счета",
          },
        ]}
      />
      <Button onClick={onChangePass} loading={restoreIsLoading}>
        Сменить пароль
      </Button>
      {restoreEmail && (
        <Alert
          message="Проверьте Ваш почтовый ящик"
          description={`На Вашу почту ${restoreEmail} отправлено письмо с инструкцией по восстановлению пароля.`}
          type="success"
          showIcon
        />
      )}
      <ErrorAlert error={restoreError} />
      <Button onClick={onLogout} danger loading={logoutIsLoading}>
        Выйти
      </Button>

      <ErrorAlert error={logoutError} />
    </Panel>
  );
};
