import { createAsyncThunk } from "@reduxjs/toolkit";
import { loginActions } from ".";
import * as api from "../../../api/unauthorized";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";
import Cookies from "js-cookie";
import { userActions } from "..";

export const login = createAsyncThunk<api.TLoginResponse, api.TLoginRequest>(
  "user/login",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(loginActions.setLoading(true));
    dispatch(loginActions.setError(null));
    return api
      .login(payload)
      .then(({ data }) => {
        Cookies.set("token", data.accessToken);
        dispatch(userActions.setUser(data.user));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(loginActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(loginActions.setLoading(false));
      });
  }
);
