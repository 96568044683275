import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOffersForSellerByApplicationIdState } from "./types";

import { RequestError, TOfferForSeller } from "../../../types";

const initialState: TOffersForSellerByApplicationIdState = {
  isLoading: false,
  error: null,
  offersForSeller: undefined,
};

export const OffersForSellerByApplicationIdSlice = createSlice({
  name: "offersForSeller/ByApplicationId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOffersForSeller: (state, { payload }: PayloadAction<TOfferForSeller[]>) => {
      state.offersForSeller = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const offersForSellerByApplicationIdActions = OffersForSellerByApplicationIdSlice.actions;
export const offersForSellerByApplicationIdReducer = OffersForSellerByApplicationIdSlice.reducer;

export * as offersForSellerByApplicationIdSelectors from "./selectors";
