import { createAsyncThunk } from "@reduxjs/toolkit";
import { colleagueAddActions } from "./add";
import { colleaguesByCabinetActions } from "./byCabinet";
import { colleagueDeleteActions } from "./delete";
import { colleagueUpdateActions } from "./update";

export const clearColleagues = createAsyncThunk<void, void>(
  "colleagues/clearColleagues",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(colleagueAddActions.clearState());
    dispatch(colleaguesByCabinetActions.clearState());
    dispatch(colleagueDeleteActions.clearState());
    dispatch(colleagueUpdateActions.clearState());
  }
);
