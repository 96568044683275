import React, { FC, useCallback } from "react";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { applicationPositionCategoryIdOptions, applicationStatusIdOptions } from "../../../../../constants/options";
import { Dayjs } from "dayjs";
import { DATE_FORMAT } from "../../../../../constants/dates";

type TSearchFormProps = {
  onSearch: (value?: string) => void;
  isLoading: boolean;
};

type TSearchFormValues = {
  name: string;
  creationDt: [Dayjs, Dayjs];
  statusId: number;
  id: number;
  resourceCategoryId: number;
};

export const SearchForm: FC<TSearchFormProps> = ({ onSearch, isLoading }) => {
  const onSearchHandler = useCallback(
    (values: TSearchFormValues) => {
      const { name, statusId, id, creationDt, resourceCategoryId } = values;

      onSearch(
        [
          name ? `name:${name}` : undefined,
          statusId ? `statusId:${statusId}` : undefined,
          id ? `id:${id}` : undefined,

          creationDt
            ? [
                `creationDt>${creationDt[0].startOf("day").valueOf()}`,
                `creationDt<${creationDt[1].endOf("day").valueOf()}`,
              ].join(",")
            : undefined,
          resourceCategoryId
            ? `applicationPosititons.resourceCategoryId:${resourceCategoryId}`
            : undefined,
        ]
          .filter((item) => !!item)
          .join(",")
      );
    },
    [onSearch]
  );

  return (
    <>
      <Collapse
        items={[
          {
            label: <Typography.Text strong>Поиск по фильтрам</Typography.Text>,
            children: (
              <Form
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 28 }}
                // style={{ maxWidth: "600px" }}
                onFinish={onSearchHandler}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelWrap
                colon={false}
                layout="vertical"
              >
                <Row gutter={24} style={{ alignItems: "flex-end" }}>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues> label="Название" name="name">
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Статус"
                      name="statusId"
                    >
                      <Select options={applicationStatusIdOptions} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="ID"
                      name="id"
                      rules={[
                        {
                          pattern: new RegExp(/^\d{1,}$/),
                          message: "Номер заявки должен состоять из цифр",
                        },
                      ]}
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Дата создания"
                      name="creationDt"
                    >
                      <DatePicker.RangePicker
                        format={DATE_FORMAT}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} style={{ alignItems: "flex-end" }}>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Товарные категории"
                      name="resourceCategoryId"
                    >
                      <Select options={applicationPositionCategoryIdOptions} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={24} style={{ alignItems: "flex-end" }}>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Поиск
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            ),
          },
        ]}
      />
    </>
  );
};
