import { RequestPromise, apiRequest } from "../../../utils/api";
// import { MOCKED_SET_PASSWORD } from "./mock";

export const setPassword = (
  data: TSetPasswordRequest
): RequestPromise<TSetPasswordResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({ data: MOCKED_SET_PASSWORD });
  //   }, 1000);
  // }) as never;

  return apiRequest.post(`/unauthorized/setpassword`, data);
};

export type TSetPasswordResponse = {
  email: string;
};

export type TSetPasswordRequest = {
  email: string;
  token: string;
  newpassword: string;
};
