import { createAsyncThunk } from "@reduxjs/toolkit";
import { colleagueAddActions } from ".";
import * as api from "../../../api/users";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const addColleague = createAsyncThunk<
  api.TAddUserResponse,
  api.TAddUserResponse
>("colleagues/addColleague", (payload, { dispatch, rejectWithValue }) => {
  dispatch(colleagueAddActions.setLoading(true));
  dispatch(colleagueAddActions.setError(null));
  return api
    .addUser(payload)
    .then(({ data }) => {
      dispatch(colleagueAddActions.setColleague(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(colleagueAddActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(colleagueAddActions.setLoading(false));
    });
});
