import React, { FC, useCallback, useMemo } from "react";
import {
  Button,
  Col,
  Collapse,
  DatePicker,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from "antd";
import { applicationStatusIdOptions } from "../../../../../constants/options";
import { TAccount, TPerson, TProject } from "../../../../../types";
import { Dayjs } from "dayjs";
import { DATE_FORMAT } from "../../../../../constants/dates";

type TSearchFormProps = {
  onSearch: (value?: string) => void;
  isLoading: boolean;
  projects?: TProject[];
  users?: TPerson[];
  accounts?: TAccount[];
};

type TSearchFormValues = {
  name: string;
  statusId: number;
  projectId: number;
  creatorId: number;
  responsibleId: number;
  accountId: number;
  id: number;
  creationDt: [Dayjs, Dayjs];
  deliveryDueDt: [Dayjs, Dayjs];
};

export const SearchForm: FC<TSearchFormProps> = ({
  onSearch,
  isLoading,
  projects,
  users,
  accounts,
}) => {
  const projectsOptions = useMemo(
    () =>
      (projects || []).map((project) => ({
        label: project?.project_name,
        value: project?.project_id,
      })),
    [projects]
  );
  const usersOptions = useMemo(
    () =>
      (users || []).map((user) => ({
        label: user?.full_name,
        value: user?.user_id,
      })),
    [users]
  );
  const accountsOptions = useMemo(
    () =>
      (accounts || []).map((account) => ({
        label: account?.name,
        value: account?.account_id,
      })),
    [accounts]
  );

  const onSearchHandler = useCallback(
    (values: TSearchFormValues) => {
      const {
        name,
        statusId,
        id,
        projectId,
        creatorId,
        responsibleId,
        accountId,
        creationDt,
        deliveryDueDt,
      } = values;


      onSearch(
        [
          name ? `name:${name}` : undefined,
          statusId ? `statusId:${statusId}` : undefined,
          id ? `id:${id}` : undefined,

          projectId ? `projectId:${projectId}` : undefined,
          accountId ? `accountId:${accountId}` : undefined,

          creatorId ? `creatorId:${creatorId}` : undefined,
          responsibleId ? `responsibleId:${responsibleId}` : undefined,

          creationDt
            ? [
                `creationDt>${creationDt[0].startOf("day").valueOf()}`,
                `creationDt<${creationDt[1].endOf("day").valueOf()}`,
              ].join(",")
            : undefined,
          deliveryDueDt
            ? [
                `deliveryDueDt>${deliveryDueDt[0].startOf("day").valueOf()}`,
                `deliveryDueDt<${deliveryDueDt[1].endOf("day").valueOf()}`,
              ].join(",")
            : undefined,
        ]
          .filter((item) => !!item)
          .join(",")
      );
    },
    [onSearch]
  );

  return (
    <>
      <Collapse
        items={[
          {
            label: <Typography.Text strong>Поиск по фильтрам</Typography.Text>,
            children: (
              <Form
                name="basic"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 28 }}
                // style={{ maxWidth: "600px" }}
                onFinish={onSearchHandler}
                // onFinishFailed={onFinishFailed}
                autoComplete="off"
                labelWrap
                colon={false}
                layout="vertical"
              >
                <Row gutter={24} style={{ alignItems: "flex-end" }}>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues> label="Название" name="name">
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Статус"
                      name="statusId"
                    >
                      <Select options={applicationStatusIdOptions} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="ID"
                      name="id"
                      rules={[
                        {
                          pattern: new RegExp(/^\d{1,}$/),
                          message: "Номер заявки должен состоять из цифр",
                        },
                      ]}
                    >
                      <Input allowClear />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} style={{ alignItems: "flex-end" }}>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Проект"
                      name="projectId"
                    >
                      <Select options={projectsOptions} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues> label="Счет" name="accountId">
                      <Select options={accountsOptions} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Создатель"
                      name="creatorId"
                    >
                      <Select options={usersOptions} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Ответственный"
                      name="responsibleId"
                    >
                      <Select options={usersOptions} allowClear />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} style={{ alignItems: "flex-end" }}>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Дата создания"
                      name="creationDt"
                    >
                      <DatePicker.RangePicker
                        format={DATE_FORMAT}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item<TSearchFormValues>
                      label="Желаемая дата поставки"
                      name="deliveryDueDt"
                    >
                      <DatePicker.RangePicker
                        format={DATE_FORMAT}
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24} style={{ alignItems: "flex-end" }}>
                  <Col span={24}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                      >
                        Поиск
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            ),
          },
        ]}
      />
    </>
  );
};
