import { createAsyncThunk } from "@reduxjs/toolkit";
import { accountAddActions } from "./add";
import { accountByCabinetIdActions } from "./byCabinet";
import { accountByEntityIdActions } from "./byEntity";
import { accountDeleteActions } from "./delete";
import { accountUpdateActions } from "./update";

export const clearAccounts = createAsyncThunk<void, void>(
  "account/clearAccounts",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(accountAddActions.clearState());
    dispatch(accountByCabinetIdActions.clearState());
    dispatch(accountByEntityIdActions.clearState());
    dispatch(accountDeleteActions.clearState());
    dispatch(accountUpdateActions.clearState());
  }
);
