import React, { useEffect } from "react";
import { ContentStyled, LayoutStyled } from "./App.styles";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";
import { MainRouter } from "../MainRouter/MainRouter";
import Cookies from "js-cookie";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { userSelectors } from "../../../store/user";
import { getUser } from "../../../store/user/thunk";
import { Breadcrumbs } from "../Breadcrumbs/Breadcrumbs";

const App = () => {
  const dispatch = useAppDispatch();
  const cookie = Cookies.get("token");

  const { user } = useAppSelector(userSelectors.getState);

  useEffect(() => {
    if (!user && cookie) {
      dispatch(getUser());
    }
  }, [cookie, dispatch, user]);

  return (
    <LayoutStyled>
      <Header />
      <ContentStyled>
        <Breadcrumbs />
        <MainRouter />
      </ContentStyled>
      <Footer />
    </LayoutStyled>
  );
};

export default App;
