import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TEntitiesByCabinetIdState } from "./types";

import { RequestError, TEntity } from "../../../types";

const initialState: TEntitiesByCabinetIdState = {
  isLoading: false,
  error: null,
  entities: undefined,
};

export const EntitiesByCabinetIdSlice = createSlice({
  name: "entity/byCabinetId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setEntities: (state, { payload }: PayloadAction<TEntity[]>) => {
      state.entities = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const entitiesByCabinetIdActions = EntitiesByCabinetIdSlice.actions;
export const entitiesByCabinetIdReducer = EntitiesByCabinetIdSlice.reducer;

export * as entitiesByCabinetIdSelectors from "./selectors";
