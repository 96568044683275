import styled from "@emotion/styled";
import { Layout } from "antd";

const { Header } = Layout;

export const HeaderStyled = styled(Header)`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
`;
