import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb } from "antd";
import React, { ComponentProps, useCallback, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAppSelector } from "../../../store/store";
import { userSelectors } from "../../../store/user";
import { colleaguesByCabinetSelectors } from "../../../store/colleagues/byCabinet";
import { accountsByEntityIdSelectors } from "../../../store/accounts/byEntity";
import { projectsByCabinetSelectors } from "../../../store/projects/byCabinet";
import { applicationByIdSelectors } from "../../../store/applications/byId";
import { applicationForSellerByIdSelectors } from "../../../store/applicationsForSeller/byId";

const breadcrumbsStopList = [
  "/login",
  "/registration",
  "/restorepass",
  "/emailpass",
  "/404",
];

const basicPathMapper: Record<string, string> = {
  "/profile": "Профиль",
  "/personal": "Личная информация",
  "/companies": "Мои компании",
  "/colleagues": "Коллеги",
  "/accounts": "Счета",
  "/projects": "Проекты",
  "/applications": "Заявки",
  "/approvements": "Согласования",
  "/availableapplications": "Заявки для поставщиков",
  "/myoffers": "Предложения",
  "/offers": "Предложения по моим заявкам",
};

export const Breadcrumbs = () => {
  const location = useLocation();

  const { user } = useAppSelector(userSelectors.getState);
  const { users } = useAppSelector(colleaguesByCabinetSelectors.getState);
  const { accounts } = useAppSelector(accountsByEntityIdSelectors.getState);
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { application } = useAppSelector(applicationByIdSelectors.getState);
  const { application: applicationForSeller } = useAppSelector(
    applicationForSellerByIdSelectors.getState
  );

  const getPathnameText = useCallback(
    (path: string): string | undefined => {
      const basicPath = basicPathMapper[path];
      if (basicPath) {
        return basicPath;
      } else if (/^\/companies\/\d{1,}/.test(path)) {
        if (/^\/companies\/\d{1,}$/.test(path)) {
          const pathArr = path.split("/");
          const id = pathArr?.[pathArr.length - 1];
          return user?.entities?.find(
            (entity) => entity?.entity?.entity_id === Number(id)
          )?.entity?.short_name;
        } else if (/^\/companies\/\d{1,}\/accounts$/.test(path)) {
          return basicPathMapper["/accounts"];
        } else if (/^\/companies\/\d{1,}\/accounts\/\d{1,}$/.test(path)) {
          const pathArr = path.split("/");
          const entityId = pathArr?.[2];
          const accountId = pathArr?.[4];
          return accounts?.[Number(entityId)]?.find(
            (account) => account?.account_id === Number(accountId)
          )?.name;
        }
      } else if (/^\/colleagues\/\d{1,}$/.test(path)) {
        const pathArr = path.split("/");
        const id = pathArr?.[pathArr.length - 1];
        return users?.find((user) => user?.user_id === Number(id))?.full_name;
      } else if (/^\/projects\/\d{1,}$/.test(path)) {
        const pathArr = path.split("/");
        const id = pathArr?.[pathArr.length - 1];
        return projects?.find((project) => project?.project_id === Number(id))
          ?.project_name;
      } else if (/^\/applications\/\d{1,}$/.test(path)) {
        const pathArr = path.split("/");
        const id = pathArr?.[pathArr.length - 1];
        return application?.application_id === Number(id)
          ? application?.application_name
          : undefined;
      } else if (/^\/availableapplications\/\d{1,}$/.test(path)) {
        const pathArr = path.split("/");
        const id = pathArr?.[pathArr.length - 1];
        return applicationForSeller?.application_id === Number(id)
          ? applicationForSeller?.application_name
          : undefined;
      } else if (/^\/myoffers\/\d{1,}$/.test(path)) {
        const pathArr = path.split("/");
        const id = pathArr?.[pathArr.length - 1];
        return `Предложение #${id}`;
      } else if (/^\/offers\/\d{1,}$/.test(path)) {
        const pathArr = path.split("/");
        const id = pathArr?.[pathArr.length - 1];
        return `Предложение #${id}`;
      }
    },
    [accounts, projects, user, users, application, applicationForSeller]
  );

  const items: ComponentProps<typeof Breadcrumb>["items"] = useMemo(() => {
    const pathSnippets = location.pathname.split("/").filter((i) => i);

    return [
      {
        title: (
          <Link to={"/"}>
            <HomeOutlined />
          </Link>
        ),
      },
    ].concat(
      pathSnippets.map((item, index, self) => {
        const path = `/${self.slice(0, index + 1).join("/")}`;
        return {
          title:
            index + 1 === self?.length ? (
              <>{getPathnameText(path) || item}</>
            ) : (
              <Link to={path}>{getPathnameText(path) || item}</Link>
            ),
        };
      })
    );
  }, [getPathnameText, location.pathname]);

  return !breadcrumbsStopList.includes(location.pathname) &&
    items?.length > 1 ? (
    <Breadcrumb items={items} style={{ alignSelf: "flex-start" }} />
  ) : null;
};
