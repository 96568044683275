import { TApplicationPosition } from "../../types";

export const applicationPositionCategoryIdMapper: Record<
  TApplicationPosition["category_id"],
  string
> = {
  1: "Материалы для строительных и дорожных работ",
  2: "Щебень, гравий, песок, шлаки, смеси, глины, грунты",
  3: "Цементы, гипс, известь",
  4: "Смеси бетонные, растворы, смеси строительные и асфальтобетонные",
  5: "Изделия из бетона, цемента и гипса",
  6: "Изделия керамические строительные",
  7: "Металлоконструкции строительные и их части из черных металлов",
  8: "Изделия металлические, металлопрокат, канаты",
  9: "Металлоконструкции строительные и их части из алюминия и алюминиевых сплавов",
  10: "Изделия прокатно-тянутые из цветных металлов и цветные металлы",
  11: "Изделия и конструкции из дерева и пластмассовых профилей",
  12: "Материалы и изделия кровельные рулонные, гидроизоляционные и теплоизоляционные, звукоизоляционные, черепица, водосточные системы",
  13: "Изделия из природного камня",
  14: "Материалы лакокрасочные, антикоррозийные, защитные и аналогичные покрытия, клеи",
  15: "Малые архитектурные формы",
  16: "Материалы для садово-паркового и зеленого строительства",
  17: "Материалы и изделия огнеупорные",
  18: "Материалы и изделия для систем водоснабжения, канализации, теплоснабжения, газоснабжения",
  19: "Материалы и изделия для систем вентиляции и кондиционирования воздуха",
  20: "Материалы монтажные и электроустановочные, изделия и конструкции",
  21: "Продукция кабельная",
  22: "Материалы для систем и сооружений связи, радиовещания и телевидения",
  23: "Трубы и трубопроводы, фасонные и соединительные части, фитинги металлические",
  24: "Трубы и трубопроводы, фасонные и соединительные части, фитинги из других материалов, кроме бетонных",
  25: "Материалы для строительства железных дорог",
  26: "Материалы и изделия для метрополитенов и тоннелей",
  27: "Материалы и изделия для сетей экологически чистого транспорта",
  28: "Прочие материалы, изделия, конструкции",
  29: "Оборудование и устройства электронные связи, радиовещания, телевидения, охранно-пожарная сигнализация",
  30: "Оборудование, устройства и аппаратура электрические",
  31: "Оборудование, устройства и аппаратура для систем теплоснабжения",
  32: "Оборудование, устройства и аппаратура для систем вентиляции и кондиционирования воздуха",
  33: "Оборудование, устройства и аппаратура для водоснабжения и канализации",
  34: "Оборудование, устройства и аппаратура для системы газоснабжения",
  35: "Лифты",
  36: "Насосы и станции для перекачки и поднятия жидкостей",
  37: "Арматура трубопроводная и воздуховодная с электроприводом",
  38: "Оборудование для строительства железных дорог",
  39: "Прочее оборудование",
};
