import { TProject } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const getProjectsByCabinet = (
  id: TGetProjectsByCabinetRequest
): RequestPromise<TGetProjectsByCabinetResponse> => {
  return apiRequest.get(`/project/cabinet/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TGetProjectsByCabinetRequest = number;

export type TGetProjectsByCabinetResponse = TProject[];
