import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TEntityUpdateState } from "./types";

import { RequestError, TEntity } from "../../../types";

const initialState: TEntityUpdateState = {
  isLoading: false,
  error: null,
  entity: undefined,
};

export const EntityUpdateSlice = createSlice({
  name: "entity/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setEntity: (state, { payload }: PayloadAction<TEntity>) => {
      state.entity = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const entityUpdateActions = EntityUpdateSlice.actions;
export const entityUpdateReducer = EntityUpdateSlice.reducer;

export * as entityUpdateSelectors from "./selectors";
