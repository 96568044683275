import React, { FC, useMemo } from "react";
import {
  TApplication,
  TApplicationForSeller,
  TApplicationPosition,
} from "../../../../../types";
import { Table } from "antd";
import { applicationPositionUnitTypeIdMapper } from "../../../../../utils/mappers";
import { ColumnsType } from "antd/es/table";

type TApplicationsTableExpandableRowProps = {
  application: TApplication | TApplicationForSeller;
};

export const ApplicationsTableExpandableRow: FC<
  TApplicationsTableExpandableRowProps
> = ({ application }) => {
  const columns: ColumnsType<TApplicationPosition> = useMemo(
    () => [
      {
        title: "Наименование",
        dataIndex: "position_name",
        width: 600,
      },
      {
        title: "Количество",
        key: "unit_amount",
        render: (value: TApplicationPosition) =>
          [
            value?.unit_amount?.toString()?.replaceAll(".", ","),
            applicationPositionUnitTypeIdMapper[value?.unit_type_id]
              ?.shortName || value?.unit_type_id,
          ].join(" "),
      },
    ],
    []
  );

  return (
    <Table
      dataSource={application?.positions}
      columns={columns}
      pagination={false}
      size="small"
      showHeader={false}
    />
  );
};
