import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForSellerUpdateStatusActions } from ".";
import * as api from "../../../api/offers_for_sellers";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const updateOfferForSellerStatus = createAsyncThunk<
  api.TUpdateOfferForSellerStatusResponse,
  api.TUpdateOfferForSellerStatusRequest
>(
  "offersForSeller/updateOfferForSellerStatus",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(offerForSellerUpdateStatusActions.setLoading(true));
    dispatch(offerForSellerUpdateStatusActions.setError(null));
    return api
      .updateOfferForSellerStatus(payload)
      .then(({ data }) => {
        dispatch(offerForSellerUpdateStatusActions.setOfferForSeller(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offerForSellerUpdateStatusActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offerForSellerUpdateStatusActions.setLoading(false));
      });
  }
);
