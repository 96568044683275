import React, { FC, useMemo } from "react";
import {
  TApplicationPosition,
  TOfferForBuyer,
  TOfferForSeller,
  TOfferPosition,
} from "../../../../../types";
import { Table } from "antd";
import { applicationPositionUnitTypeIdMapper } from "../../../../../utils/mappers";
import { ColumnsType } from "antd/es/table";

type TOffersTableExpandableRowProps = {
  offer: TOfferForSeller | TOfferForBuyer
};

export const OffersTableExpandableRow: FC<
  TOffersTableExpandableRowProps
> = ({ offer }) => {
  const columns: ColumnsType<TOfferPosition> = useMemo(
    () => [
      {
        title: "Наименование",
        dataIndex: "position_name",
        width: 600,
      },
      {
        title: "Количество",
        key: "unit_amount",
        render: (value: TApplicationPosition) =>
          [
            value?.unit_amount?.toString()?.replaceAll(".", ","),
            applicationPositionUnitTypeIdMapper[value?.unit_type_id]
              ?.shortName || value?.unit_type_id,
          ].join(" "),
      },
    ],
    []
  );

  return (
    <Table
      dataSource={offer?.positions}
      columns={columns}
      pagination={false}
      size="small"
      showHeader={false}
    />
  );
};
