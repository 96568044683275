import { createAsyncThunk } from "@reduxjs/toolkit";
import { applicationAddActions } from ".";
import * as api from "../../../api/applications";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const addApplication = createAsyncThunk<
  api.TAddApplicationResponse,
  api.TAddApplicationRequest
>("application/addApplication", (payload, { dispatch, rejectWithValue }) => {
  dispatch(applicationAddActions.setLoading(true));
  dispatch(applicationAddActions.setError(null));
  return api
    .addApplication(payload)
    .then(({ data }) => {
      dispatch(applicationAddActions.setApplication(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(applicationAddActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(applicationAddActions.setLoading(false));
    });
});
