import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TColleagueAddState } from "./types";

import { RequestError, TPerson } from "../../../types";

const initialState: TColleagueAddState = {
  isLoading: false,
  error: null,
  user: undefined,
};

export const ColleagueAddSlice = createSlice({
  name: "colleagues/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setColleague: (state, { payload }: PayloadAction<TPerson>) => {
      state.user = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const colleagueAddActions = ColleagueAddSlice.actions;
export const colleagueAddReducer = ColleagueAddSlice.reducer;

export * as colleagueAddSelectors from "./selectors";
