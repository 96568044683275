export type TEntity = {
  accountant_name?: string;
  cabinet_id: number; //id кабинета пользователя
  company_email?: string;
  company_phone?: string;
  entity_id: number; //id юр. лица (таблица legal_entity)
  full_name: string;
  inn: string;
  is_active: boolean;
  kpp: string;
  legal_address: string;
  ogrn: string;
  opf: EEntityOpf;
  parent_id?: number;
  physical_address: string;
  short_name: string;
  website?: string;
};
export enum EEntityOpf {
  OOO = "ООО",
  PAO = "ПАО",
  AO = "АО",
  IP = "ИП",
  NKO = "НКО",
}
