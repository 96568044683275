import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const logout = (): RequestPromise<TLogoutResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({});
  //   }, 1000);
  // }) as never;

  return apiRequest.get(`/unauthorized/logout`, {
    headers: { Authorization: "Bearer " + Cookies.get("token") },
  });
};

export type TLogoutResponse = {};
