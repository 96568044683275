import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationUpdateStatusState } from "./types";

import { TUpdateApplicationStatusResponse } from "../../../api/applications";
import { RequestError } from "../../../types";

const initialState: TApplicationUpdateStatusState = {
  isLoading: false,
  error: null,
  applicationId: undefined,
  new_status_id: undefined,
};

export const ApplicationUpdateStatusSlice = createSlice({
  name: "application/update_status",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApplication: (
      state,
      { payload }: PayloadAction<TUpdateApplicationStatusResponse>
    ) => {
      state.applicationId = payload?.application_id;
      state.new_status_id = payload?.new_status_id;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const applicationUpdateStatusActions =
  ApplicationUpdateStatusSlice.actions;
export const applicationUpdateStatusReducer =
  ApplicationUpdateStatusSlice.reducer;

export * as applicationUpdateStatusSelectors from "./selectors";
