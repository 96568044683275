import React, { FC, useMemo } from "react";
import { TPerson } from "../../../types";
import {
  SmileOutlined,
  MailOutlined,
  PhoneOutlined,
  PushpinOutlined,
  ShopOutlined,
  DesktopOutlined,
  CheckOutlined,
  CloseOutlined,
  SwapRightOutlined,
} from "@ant-design/icons";
import { Typography, List } from "antd";
import { Link } from "react-router-dom";
import { Panel } from "../Panel/Panel";
import { Wrapper } from "./UserData.styles";

type TUserDataProps = {
  user: TPerson;
  withActions?: boolean;
};

export const UserData: FC<TUserDataProps> = ({ user, withActions }) => {
  const generalData = useMemo(
    () => [
      { label: "ФИО", value: user?.full_name, icon: <SmileOutlined /> },
      { label: "E-mail", value: user?.email, icon: <MailOutlined /> },
      { label: "Телефон", value: user?.phone, icon: <PhoneOutlined /> },
      { label: "Должность", value: user?.position, icon: <PushpinOutlined /> },
    ],
    [user]
  );
  const cabinetData = useMemo(
    () => [
      {
        label: "Название",
        value: user?.cabinet?.name,
        icon: <ShopOutlined />,
      },
      {
        label: "Роль",
        value: user?.cabinet_role_name,
        icon: <PushpinOutlined />,
      },
      {
        label: "Описание роли",
        value: user?.cabinet_role_description,
        icon: <DesktopOutlined />,
      },
    ],
    [user]
  );
  const entitiesData = useMemo(
    () =>
      (user?.entities || []).map((entity) => ({
        icon: entity?.entity?.is_active ? <CheckOutlined /> : <CloseOutlined />,
        value: entity?.entity?.full_name,
        label: "",
      })),
    [user]
  );
  return (
    <Wrapper>
      <Panel
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={3} style={{ marginTop: 0 }}>
          Общая информация
        </Typography.Title>
        <List
          dataSource={generalData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item?.icon}
                title={item?.value}
                description={item?.label}
              />
            </List.Item>
          )}
        />
      </Panel>
      <Panel
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={3} style={{ marginTop: 0 }}>
          Кабинет
        </Typography.Title>
        <List
          dataSource={cabinetData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item?.icon}
                title={item?.value}
                description={item?.label}
              />
            </List.Item>
          )}
        />
      </Panel>
      <Panel
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {withActions ? (
          <Link to="/companies">
            <Typography.Title level={3} style={{ marginTop: 0 }}>
              Юр. лица <SwapRightOutlined />
            </Typography.Title>
          </Link>
        ) : (
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Юр. лица
          </Typography.Title>
        )}
        <List
          dataSource={entitiesData}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                avatar={item?.icon}
                title={item?.value}
                description={item?.label}
              />
            </List.Item>
          )}
        />
      </Panel>
    </Wrapper>
  );
};
