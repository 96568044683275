import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TEntityDeleteState } from "./types";

import { RequestError, TEntity } from "../../../types";

const initialState: TEntityDeleteState = {
  isLoading: false,
  error: null,
  entity: undefined,
};

export const EntityDeleteSlice = createSlice({
  name: "entity/delete",
  initialState,
  reducers: {
    clearState: () => initialState,
    setEntity: (state, { payload }: PayloadAction<TEntity>) => {
      state.entity = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const entityDeleteActions = EntityDeleteSlice.actions;
export const entityDeleteReducer = EntityDeleteSlice.reducer;

export * as entityDeleteSelectors from "./selectors";
