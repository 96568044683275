import { createAsyncThunk } from "@reduxjs/toolkit";
import { colleagueUpdateActions } from ".";
import * as api from "../../../api/users";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const updateColleague = createAsyncThunk<
  api.TUpdateUserResponse,
  api.TUpdateUserRequest
>("colleagues/updateColleague", (payload, { dispatch, rejectWithValue }) => {
  dispatch(colleagueUpdateActions.setLoading(true));
  dispatch(colleagueUpdateActions.setError(null));
  return api
    .updateUser(payload)
    .then(({ data }) => {
      dispatch(colleagueUpdateActions.setColleague(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(colleagueUpdateActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(colleagueUpdateActions.setLoading(false));
    });
});
