import styled from "@emotion/styled";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex: 1;

  .ant-card-head-title {
    color: rgba(0, 0, 0, 0.45);
  }
`;
