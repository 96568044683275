import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AccountData, AccountEditForm } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Alert, Button, Typography } from "antd";
import { useParams } from "react-router-dom";
import { userSelectors } from "../../../store/user";
import { TAccount } from "../../../types";
import { CTABlock, Wrapper } from "./Account.styles";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import {
  accountDeleteActions,
  accountDeleteSelectors,
} from "../../../store/accounts/delete";
import {
  accountUpdateActions,
  accountUpdateSelectors,
} from "../../../store/accounts/update";
import { accountsByEntityIdSelectors } from "../../../store/accounts/byEntity";
import { getAccountsByEntityId } from "../../../store/accounts/byEntity/thunk";
import { updateAccount } from "../../../store/accounts/update/thunk";
import { deleteAccount } from "../../../store/accounts/delete/thunk";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

export const Account = () => {
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const { accounts } = useAppSelector(accountsByEntityIdSelectors.getState);
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    accountDeleteSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    accountUpdateSelectors.getState
  );

  const { entity_id, account_id } = useParams<{
    entity_id: string;
    account_id: string;
  }>();

  const entity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === Number(entity_id)
      )?.entity,
    [entity_id, user]
  );

  const account = useMemo(
    () =>
      accounts[Number(entity_id)]?.find(
        (account) => account?.account_id === Number(account_id)
      ),
    [account_id, accounts, entity_id]
  );

  const canUpdateDelete = useMemo(
    () =>
      (user?.cabinet?.cabinet_id === entity?.cabinet_id &&
        account?.entity_id === entity?.entity_id &&
        user?.cabinet_role_id === 2) ||
      user?.is_admin,
    [account, entity, user]
  );

  const getAccounts = useCallback(() => {
    entity_id && dispatch(getAccountsByEntityId(Number(entity_id)));
  }, [dispatch, entity_id]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TAccount) => {
      account &&
        dispatch(
          updateAccount({
            ...account,
            ...values,
            /* TODO убрать BIK, OKATO */
            BIK: values?.bik,
            OKATO: values?.okato,
          })
        )
          .unwrap()
          .then(() => {
            onCancelEdit();
            getAccounts();
          });
    },
    [account, dispatch, getAccounts, onCancelEdit]
  );

  const onDelete = useCallback(() => {
    account &&
      dispatch(deleteAccount(account?.account_id))
        .unwrap()
        .then(() => {
          getAccounts();
        });
  }, [account, dispatch, getAccounts]);

  useEffect(() => {
    !account && getAccounts();
  }, [account, dispatch, entity_id, getAccounts]);

  useEffect(() => {
    return () => {
      dispatch(accountDeleteActions.clearState());
      dispatch(accountUpdateActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ marginTop: 0 }}>
        {account?.name || "Счет"}
      </Typography.Title>

      {account ? (
        <>
          {canUpdateDelete && (
            <CTABlock>
              <Button
                icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                onClick={isEditing ? onCancelEdit : onEdit}
              >
                {isEditing ? "Отмена" : "Редактировать"}
              </Button>

              {!isEditing && (
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={onDelete}
                  loading={deleteIsLoading}
                >
                  Удалить
                </Button>
              )}
            </CTABlock>
          )}

          {!isEditing && <ErrorAlert error={deleteError} />}

          {isEditing ? (
            <AccountEditForm
              account={account}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
            />
          ) : (
            <AccountData account={account} />
          )}
        </>
      ) : (
        <Alert message="Данные по счету отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
