import { createAsyncThunk } from "@reduxjs/toolkit";
import { logoutActions } from ".";
import * as api from "../../../api/unauthorized";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";
import Cookies from "js-cookie";
import { clearAccounts } from "../../accounts/thunk";
import { clearApplications } from "../../applications/thunk";
import { clearApplicationsForSeller } from "../../applicationsForSeller/thunk";
import { clearApprovements } from "../../approvements/thunk";
import { clearColleagues } from "../../colleagues/thunk";
import { clearEntities } from "../../entities/thunk";
import { clearProjects } from "../../projects/thunk";
import { clearUser } from "../thunk";
import { clearOffersForSeller } from "../../offersForSeller/thunk";
import { clearOffersForBuyer } from "../../offersForBuyer/thunk";

export const logout = createAsyncThunk<api.TLogoutResponse, undefined>(
  "user/logout",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(logoutActions.setLoading(true));
    dispatch(logoutActions.setError(null));

    return api
      .logout()
      .then(({ data }) => {
        Cookies.remove("token");
        dispatch(clearUser());
        dispatch(clearAccounts());
        dispatch(clearApplications());
        dispatch(clearApplicationsForSeller());
        dispatch(clearApprovements());
        dispatch(clearColleagues());
        dispatch(clearEntities());
        dispatch(clearOffersForSeller());
        dispatch(clearOffersForBuyer());
        dispatch(clearProjects());
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(logoutActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(logoutActions.setLoading(false));
      });
  }
);
