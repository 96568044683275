import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAccountByCabinetIdState } from "./types";

import { RequestError, TAccount } from "../../../types";

const initialState: TAccountByCabinetIdState = {
  isLoading: false,
  error: null,
  accounts: undefined,
};

export const AccountByCabinetIdSlice = createSlice({
  name: "account/byCabinetId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setAccounts: (state, { payload }: PayloadAction<TAccount[]>) => {
      state.accounts = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const accountByCabinetIdActions = AccountByCabinetIdSlice.actions;
export const accountByCabinetIdReducer = AccountByCabinetIdSlice.reducer;

export * as accountsByCabinetIdSelectors from "./selectors";
