import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Panel } from "..";
import {
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
} from "antd";
import {
  RequestError,
  TAccount,
  TApplication,
  TApplicationApprovement,
  TApplicationPosition,
  TPerson,
  TProject,
} from "../../../types";
import dayjs, { Dayjs } from "dayjs";
import { DATE_TIME_FORMAT, ISO_DATE_TIME } from "../../../constants/dates";
import { toSendDateTime } from "../../../utils/dates";
import { projectRegionIdOptions } from "../../../constants/options";
import { ApprovementsSelector, PositionsSelector } from "./components";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";

type TApplicationApprovementFormValue = Omit<
  TApplicationApprovement,
  "approver"
> & {
  approver?: number;
};
type TApplicationPositionFromValue = Omit<
  TApplicationPosition,
  "unit_amount"
> & {
  unit_amount: string;
};

export type TApplicationFormValues = Omit<
  TApplication,
  | "application_delivery_due_datetime"
  | "buyer_account"
  | "responsible"
  | "approvements"
  | "positions"
> & {
  application_delivery_due_datetime?: Dayjs;
  buyer_account?: number;
  responsible?: number;
  approvements?: TApplicationApprovementFormValue[];
  positions?: TApplicationPositionFromValue[];
};

type TApplicationEditFormProps = {
  application?: TApplication;
  user?: TPerson;
  users?: TPerson[];
  projects?: TProject[];
  accounts?: TAccount[];
  onSubmit: (values: TApplication) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const ApplicationEditForm: FC<TApplicationEditFormProps> = ({
  application,
  user,
  users,
  projects,
  accounts,
  onSubmit,
  isLoading,
  error,
}) => {
  const [buyerEntity, setBuyerEntity] = useState<number | undefined>(undefined);

  const [form] = Form.useForm<TApplicationFormValues>();
  const deliveryNeeded = Form.useWatch("is_need_delivery", form);
  const postpaymentNeeded = Form.useWatch("is_postpayment", form);

  const entitiesOptions = useMemo(
    () =>
      user?.entities?.map((entity) => ({
        label: entity?.entity?.short_name,
        value: entity?.entity?.entity_id,
      })),
    [user?.entities]
  );

  const projectsOptions = useMemo(
    () =>
      (projects || [])
        .filter((project) => project?.entity_id === buyerEntity)
        .map((project) => ({
          label: project?.project_name,
          value: project?.project_id,
        })),
    [buyerEntity, projects]
  );
  const accountsOptions = useMemo(
    () =>
      (accounts || [])
        .filter((account) => account?.entity_id === buyerEntity)
        .map((account) => ({
          label: account?.name,
          value: account?.account_id,
        })),
    [accounts, buyerEntity]
  );

  const initialValues: TApplicationFormValues | undefined = useMemo(
    () =>
      application
        ? {
            ...application,
            application_delivery_due_datetime:
              application?.application_delivery_due_datetime
                ? dayjs(
                    application?.application_delivery_due_datetime,
                    ISO_DATE_TIME
                  )
                : undefined,
            buyer_account: application?.buyer_account?.account_id,
            approvements: application?.approvements?.map((approvement) => ({
              ...approvement,
              approver: approvement?.approver?.user_id,
            })),
            responsible: application?.responsible?.user_id,
            positions: application?.positions?.map((position) => ({
              ...position,
              unit_amount: position?.unit_amount
                ?.toString()
                ?.replaceAll(".", ","),
            })),
          }
        : undefined,
    [application]
  );

  const onSubmitHandler = useCallback(
    (application: TApplicationFormValues) => {
      if (application) {
        onSubmit({
          ...application,
          application_delivery_due_datetime: toSendDateTime(
            application?.application_delivery_due_datetime
          ),
          buyer_account: accounts?.find(
            (account) => account?.account_id === application?.buyer_account
          ) as TAccount,
          responsible: users?.find(
            (user) => user?.user_id === application?.responsible
          ) as TPerson,
          approvements: application?.approvements?.map((approvement) => ({
            ...approvement,
            approver: users?.find(
              (user) => user?.user_id === approvement?.approver
            ) as TPerson,
          })) as TApplicationApprovement[],
          positions: application?.positions?.map((position) => ({
            ...position,
            unit_amount: Number(position?.unit_amount?.replaceAll(",", ".")),
          })) as TApplicationPosition[],
        });
      }
    },
    [accounts, onSubmit, users]
  );

  const onEntityChange = useCallback(
    (entity_id: number) => {
      setBuyerEntity(entity_id);
      form.setFieldValue("project_id", undefined);
      form.setFieldValue("buyer_account", undefined);
    },
    [form]
  );
  const onProjectChange = useCallback(
    (project_id: number) => {
      const project = projects?.find(
        (project) => project?.project_id === project_id
      );

      form.setFieldValue("delivery_address", project?.delivery_address);
      form.setFieldValue("application_region_id", project?.region_id);
    },
    [form, projects]
  );

  useEffect(() => {
    const curProject = projects?.find(
      (project) => project?.project_id === application?.project_id
    );
    if (curProject) {
      setBuyerEntity(curProject?.entity_id);
    }
  }, [application, projects]);

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmitHandler}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={initialValues}
      >
        <Typography.Title level={4}>Параметры заявки</Typography.Title>
        <Form.Item<TApplicationFormValues>
          label="Имя заявки"
          name="application_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите имя заявки",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TApplicationFormValues>
          label="Желаемая дата поставки"
          name="application_delivery_due_datetime"
          rules={[
            {
              required: true,
              message: "Выберите желаемую дату поставки",
            },
          ]}
        >
          <DatePicker format={DATE_TIME_FORMAT} showTime minDate={dayjs()} />
        </Form.Item>
        <Form.Item
          label="Юр. лицо покупателя"
          validateStatus={!buyerEntity ? "error" : undefined}
          help={!buyerEntity ? "Выберите юр. лицо покупателя" : undefined}
        >
          <Select
            options={entitiesOptions}
            onChange={onEntityChange}
            value={buyerEntity}
          />
        </Form.Item>
        <Form.Item<TApplicationFormValues>
          label="Проект покупателя"
          name="project_id"
          rules={[
            {
              required: true,
              message: "Выберите проект покупателя",
            },
          ]}
        >
          <Select
            options={projectsOptions}
            disabled={!projectsOptions?.length}
            onChange={onProjectChange}
          />
        </Form.Item>
        <Form.Item<TApplicationFormValues>
          label="Регион"
          name="application_region_id"
          rules={[
            {
              required: true,
              message: "Выберите регион",
            },
          ]}
        >
          <Select options={projectRegionIdOptions} />
        </Form.Item>
        <Form.Item<TApplicationFormValues>
          label="Необходима доставка"
          name="is_need_delivery"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        {deliveryNeeded && (
          <Form.Item<TApplicationFormValues>
            label="Адрес доставки"
            name="delivery_address"
          >
            <Input disabled={!deliveryNeeded} />
          </Form.Item>
        )}
        <Form.Item<TApplicationFormValues>
          label="Комментарий для поставщика"
          name="application_comment"
        >
          <Input />
        </Form.Item>
        <Form.Item<TApplicationFormValues>
          label="Счёт покупателя"
          name="buyer_account"
          rules={[
            {
              required: true,
              message: "Выберите счёт покупателя",
            },
          ]}
        >
          <Select
            options={accountsOptions}
            disabled={!accountsOptions?.length}
          />
        </Form.Item>
        <Form.Item<TApplicationFormValues>
          label="Необходим отложенный платеж"
          name="is_postpayment"
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        {postpaymentNeeded && (
          <Form.Item<TApplicationFormValues>
            label="Кол-во дней отложенного платежа"
            name="postpayment_days"
          >
            <InputNumber disabled={!postpaymentNeeded} precision={0} />
          </Form.Item>
        )}

        <PositionsSelector form={form} />

        <ApprovementsSelector creator={application?.creator} users={users} />

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
