import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ProjectData, ProjectEditForm } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Alert, Button, Typography } from "antd";
import { useParams } from "react-router-dom";
import { userSelectors } from "../../../store/user";
import { TProject } from "../../../types";
import { CTABlock, Wrapper } from "./Project.styles";
import { CloseOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { projectsByCabinetSelectors } from "../../../store/projects/byCabinet";
import {
  projectDeleteActions,
  projectDeleteSelectors,
} from "../../../store/projects/delete";
import {
  projectUpdateActions,
  projectUpdateSelectors,
} from "../../../store/projects/update";
import { getProjectsByCabinet } from "../../../store/projects/byCabinet/thunk";
import { updateProject } from "../../../store/projects/update/thunk";
import { deleteProject } from "../../../store/projects/delete/thunk";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

export const Project = () => {
  const dispatch = useAppDispatch();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { isLoading: deleteIsLoading, error: deleteError } = useAppSelector(
    projectDeleteSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    projectUpdateSelectors.getState
  );

  const { project_id } = useParams<{ project_id: string }>();

  const project = useMemo(
    () =>
      (projects || []).find(
        (project) => project?.project_id === Number(project_id)
      ),
    [project_id, projects]
  );

  const entity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      )?.entity,
    [project, user]
  );

  const canUpdateDelete = useMemo(
    () =>
      ((user?.entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      )?.role_id || 0) >= 3 &&
        user?.cabinet_role_id === 2) ||
      user?.is_admin,
    [project?.entity_id, user?.cabinet_role_id, user?.entities, user?.is_admin]
  );

  const getProjects = useCallback(() => {
    user?.cabinet && dispatch(getProjectsByCabinet(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TProject) => {
      dispatch(updateProject({ ...project, ...values }))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getProjects();
        });
    },
    [dispatch, getProjects, onCancelEdit, project]
  );

  const onDelete = useCallback(() => {
    project &&
      dispatch(deleteProject(project?.project_id))
        .unwrap()
        .then(() => {
          getProjects();
        });
  }, [dispatch, getProjects, project]);

  useEffect(() => {
    !projects && getProjects();
  }, [getProjects, projects]);

  useEffect(() => {
    return () => {
      dispatch(projectDeleteActions.clearState());
      dispatch(projectUpdateActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ marginTop: 0 }}>
        {project?.project_name || "Проект"}
      </Typography.Title>

      {project ? (
        <>
          {canUpdateDelete && (
            <CTABlock>
              <Button
                icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                onClick={isEditing ? onCancelEdit : onEdit}
              >
                {isEditing ? "Отмена" : "Редактировать"}
              </Button>

              {!isEditing && (
                <Button
                  icon={<DeleteOutlined />}
                  danger
                  onClick={onDelete}
                  loading={deleteIsLoading}
                >
                  Удалить
                </Button>
              )}
            </CTABlock>
          )}

          {!isEditing && <ErrorAlert error={deleteError} />}

          {isEditing ? (
            <ProjectEditForm
              project={project}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
              entities={user?.entities || []}
            />
          ) : (
            <ProjectData project={project} entity={entity} />
          )}
        </>
      ) : (
        <Alert message="Данные по проекту отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
