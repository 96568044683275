import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TColleagueDeleteState } from "./types";

import { RequestError, TPerson } from "../../../types";

const initialState: TColleagueDeleteState = {
  isLoading: false,
  error: null,
  user: undefined,
};

export const ColleagueDeleteSlice = createSlice({
  name: "colleagues/delete",
  initialState,
  reducers: {
    clearState: () => initialState,
    setColleague: (state, { payload }: PayloadAction<TPerson>) => {
      state.user = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const colleagueDeleteActions = ColleagueDeleteSlice.actions;
export const colleagueDeleteReducer = ColleagueDeleteSlice.reducer;

export * as colleagueDeleteSelectors from "./selectors";
