import { TPerson } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const addUser = (
  data: TAddUserRequest
): RequestPromise<TAddUserResponse> => {
  return apiRequest.post(`/users/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TAddUserRequest = Omit<Partial<TPerson>, "user_id">;

export type TAddUserResponse = TPerson;
