import React, { useEffect } from "react";
import { Empty } from "antd";
import notFound from "../../../images/404.png";
import { useLocation, useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/404") {
      navigate("/404", { replace: true, relative: "path" });
    }
  }, [location.pathname, navigate]);

  return (
    <Empty
      description="404, кажется вы заблудились"
      image={notFound}
      imageStyle={{ height: "50vh", aspectRatio: 1 }}
    />
  );
};
