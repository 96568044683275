import { TPerson } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const deleteUser = (
  id: TDeleteUserRequest
): RequestPromise<TDeleteUserResponse> => {
  return apiRequest.post(`/users/delete/${id}`, undefined, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TDeleteUserRequest = number;

export type TDeleteUserResponse = TPerson;
