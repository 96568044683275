import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TProjectUpdateState } from "./types";

import { RequestError, TProject } from "../../../types";

const initialState: TProjectUpdateState = {
  isLoading: false,
  error: null,
  project: undefined,
};

export const ProjectUpdateSlice = createSlice({
  name: "project/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setProject: (state, { payload }: PayloadAction<TProject>) => {
      state.project = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const projectUpdateActions = ProjectUpdateSlice.actions;
export const projectUpdateReducer = ProjectUpdateSlice.reducer;

export * as projectUpdateSelectors from "./selectors";
