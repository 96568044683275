import React, { useCallback, useEffect } from "react";
import { Panel } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Button, Form, FormProps, Input, Typography } from "antd";
import { TLoginRequest } from "../../../api/unauthorized";
import { Link } from "react-router-dom";
import { CTABlock } from "./Login.styles";
import { loginActions, loginSelectors } from "../../../store/user/login";
import { login } from "../../../store/user/login/thunk";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

export const Login = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error } = useAppSelector(loginSelectors.getState);

  const onLogin: FormProps<TLoginRequest>["onFinish"] = useCallback(
    (values: TLoginRequest) => {
      dispatch(login(values));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(loginActions.clearState());
    };
  }, [dispatch]);

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Typography.Title level={3} style={{ margin: 0 }}>
        Авторизация
      </Typography.Title>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onLogin}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
      >
        <Form.Item<TLoginRequest>
          label="Логин"
          name="login"
          rules={[
            { required: true, whitespace: true, message: "Введите логин" },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item<TLoginRequest>
          label="Пароль"
          name="password"
          rules={[{ required: true, message: "Введите пароль" }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Войти
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
      <CTABlock>
        <Link to="/registration">
          <Button type="link">Зарегистрироваться</Button>
        </Link>
        <Link to="/restorepass">
          <Button type="link">Забыли пароль?</Button>
        </Link>
      </CTABlock>
    </Panel>
  );
};
