import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const updateOfferForSellerStatus = (
  data: TUpdateOfferForSellerStatusRequest
): RequestPromise<TUpdateOfferForSellerStatusResponse> => {
  return apiRequest.post(`/offers-for-sellers/update_status`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TUpdateOfferForSellerStatusRequest = {
  offer_id: number;
  new_status_id: number;
};

export type TUpdateOfferForSellerStatusResponse = {
  offer_id: number;
  new_status_id: number;
};
