import React, { FC, useCallback, useMemo, useState } from "react";
import {
  TApplication,
  TApplicationApprovement,
  TApplicationForSeller,
  TEntity,
  TPerson,
  TProject,
} from "../../../types";
import {
  FileTextOutlined,
  CalendarOutlined,
  ShopOutlined,
  ProjectOutlined,
  GlobalOutlined,
  PushpinOutlined,
  EllipsisOutlined,
  CreditCardOutlined,
  ClockCircleOutlined,
  UserOutlined,
  InfoOutlined,
} from "@ant-design/icons";
import { Typography, List, Tag, Card, Modal, Button } from "antd";
import { Panel } from "../Panel/Panel";
import { Wrapper } from "./ApplicationData.styles";
import { toDisplayDateTime } from "../../../utils/dates";
import {
  applicationApprovementStatusIdMapper,
  applicationApprovementTypeIdMapper,
  projectRegionIdMapper,
} from "../../../utils/mappers";
import Table, { ColumnsType } from "antd/es/table";
import { AccountData } from "../AccountData/AccountData";
import { CompanyData } from "../CompanyData/CompanyData";
import { ApplicationPositionsTable } from "./components";

type TApplicationDataProps = {
  application: TApplication | TApplicationForSeller;
  user?: TPerson;
  entity?: TEntity;
  project?: TProject;
  forSeller?: boolean;
};

export const ApplicationData: FC<TApplicationDataProps> = ({
  application,
  entity,
  project,
  forSeller,
}) => {
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);

  const openAccountModal = useCallback(() => {
    setIsAccountModalOpen(true);
  }, []);
  const closeAccountModal = useCallback(() => {
    setIsAccountModalOpen(false);
  }, []);
  const openEntityModal = useCallback(() => {
    setIsEntityModalOpen(true);
  }, []);
  const closeEntityModal = useCallback(() => {
    setIsEntityModalOpen(false);
  }, []);

  const generalData = useMemo(
    () => [
      {
        label: "Наименование заявки",
        value: application?.application_name,
        icon: <FileTextOutlined />,
      },
      {
        label: "Желаемая дата поставки",
        value: toDisplayDateTime(
          application?.application_delivery_due_datetime
        ),
        icon: <CalendarOutlined />,
      },
      {
        label: "Юр. лицо покупателя",
        value: forSeller
          ? (application as TApplicationForSeller)?.buyer_entity?.full_name
          : entity?.full_name,
        icon: <ShopOutlined />,
        additionalContent: forSeller ? (
          <Button shape="circle" icon={<InfoOutlined />} size="small" />
        ) : undefined,
        onClick: forSeller ? openEntityModal : undefined,
      },
      ...(!forSeller
        ? [
            {
              label: "Проект покупателя",
              value:
                project?.project_name ||
                (application as TApplication)?.project_id,
              icon: <ProjectOutlined />,
            },
          ]
        : []),
      {
        label: "Регион",
        value:
          projectRegionIdMapper[application?.application_region_id]?.fullName ||
          application?.application_region_id,
        icon: <GlobalOutlined />,
      },
      {
        label: "Необходима доставка",
        value: application?.is_need_delivery
          ? application?.delivery_address || "Да"
          : "Нет",
        icon: <PushpinOutlined />,
      },
      {
        label: "Комментарий для поставщика",
        value: application?.application_comment,
        icon: <EllipsisOutlined />,
      },
      {
        label: "Счёт покупателя",
        value: application?.buyer_account?.name,
        icon: <CreditCardOutlined />,
        additionalContent: forSeller ? (
          <Button shape="circle" icon={<InfoOutlined />} size="small" />
        ) : undefined,
        onClick: forSeller ? openAccountModal : undefined,
      },
      {
        label: "Необходим отложенный платеж",
        value: application?.is_postpayment
          ? application?.postpayment_days
            ? `${application?.postpayment_days} дней`
            : "Да"
          : "Нет",
        icon: <ClockCircleOutlined />,
      },
    ],
    [application, forSeller, entity, openEntityModal, project, openAccountModal]
  );

  const creatorsData = useMemo(
    () => [
      ...(!forSeller
        ? [
            {
              label: "Создатель заявки",
              value: (application as TApplication)?.creator?.full_name,
              icon: <UserOutlined />,
            },
          ]
        : []),
      {
        label: "Ответственный по заявке",
        value: (
          <>
            {application?.responsible?.full_name}{" "}
            <i>
              (
              {(
                [
                  application?.responsible?.position ? (
                    <>{application?.responsible?.position}</>
                  ) : null,
                  application?.responsible?.email ? (
                    <a href={`mailto:${application?.responsible?.email}`}>
                      {application?.responsible?.email}
                    </a>
                  ) : null,
                  application?.responsible?.phone ? (
                    <a href={`tel:${application?.responsible?.phone}`}>
                      {application?.responsible?.phone}
                    </a>
                  ) : null,
                ].filter((item) => !!item) as JSX.Element[]
              ).reduce((acc: JSX.Element[], x) => {
                return !acc?.length ? [x] : [...acc, <>, </>, x];
              }, [])}
              )
            </i>
          </>
        ),
        icon: <UserOutlined />,
        content: [
          application?.responsible?.position,
          application?.responsible?.email,
          application?.responsible?.phone,
        ].filter((item) => item),
      },
    ],
    [application, forSeller]
  );

  const approvementsColumns: ColumnsType<TApplicationApprovement> = useMemo(
    () =>
      !forSeller
        ? [
            {
              title: "Тип согласования",
              dataIndex: "approvement_type_id",
              render: (value: number) =>
                applicationApprovementTypeIdMapper[value]?.shortName || value,
            },
            {
              title: "Статус согласования",
              dataIndex: "approvement_status_id",
              render: (value: number) => {
                const statusData = applicationApprovementStatusIdMapper[value];

                return statusData ? (
                  <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
                ) : (
                  value
                );
              },
            },
            {
              title: "Согласующий",
              dataIndex: "approver",
              render: (value: TPerson) => value?.full_name,
            },
            {
              title: "Дата создания",
              dataIndex: "requested_datetime",
              render: toDisplayDateTime,
            },
            {
              title: "Дата перехода",
              dataIndex: "approvement_datetime",
              render: toDisplayDateTime,
            },
            {
              title: "Комментарий",
              dataIndex: "approvement_comment",
            },
          ]
        : [],
    [forSeller]
  );

  return (
    <>
      <Wrapper>
        <Panel
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Параметры заявки
          </Typography.Title>
          <List
            grid={{
              gutter: [24, 0],
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            dataSource={generalData}
            renderItem={(item) => (
              <List.Item>
                <Card
                  size="small"
                  title={item?.label}
                  extra={item?.additionalContent}
                  onClick={item?.onClick}
                  hoverable={!!item?.onClick}
                >
                  <Card.Meta avatar={item?.icon} title={item?.value} />
                </Card>
              </List.Item>
            )}
          />
        </Panel>

        <Panel
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Позиции заявки
          </Typography.Title>
          <ApplicationPositionsTable positions={application?.positions} />
        </Panel>

        <Panel
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Процесс заявки
          </Typography.Title>
          <List
            dataSource={creatorsData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item?.icon}
                  title={item?.value}
                  description={item?.label}
                />
              </List.Item>
            )}
          />
          {!forSeller &&
            !!(application as TApplication)?.approvements?.length && (
              <Table
                dataSource={(application as TApplication)?.approvements}
                columns={approvementsColumns}
                pagination={false}
                scroll={{ x: "1200px" }}
              />
            )}
        </Panel>
      </Wrapper>
      <Modal
        title="Счёт покупателя"
        open={isAccountModalOpen}
        onCancel={closeAccountModal}
        footer={null}
      >
        <AccountData account={application?.buyer_account} isSmall withCopy />
      </Modal>
      <Modal
        title="Юр. лицо покупателя"
        open={isEntityModalOpen}
        onCancel={closeEntityModal}
        footer={null}
      >
        <CompanyData
          entity={(application as TApplicationForSeller)?.buyer_entity}
          isSmall
          withCopy
        />
      </Modal>
    </>
  );
};
