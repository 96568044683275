import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TProjectsByCabinetState } from "./types";

import { RequestError, TProject } from "../../../types";

const initialState: TProjectsByCabinetState = {
  isLoading: false,
  error: null,
  projects: undefined,
};

export const ProjectsByCabinetSlice = createSlice({
  name: "project/byCabinet",
  initialState,
  reducers: {
    clearState: () => initialState,
    setProjects: (
      state,
      { payload }: PayloadAction<TProject[]>
    ) => {
      state.projects = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const projectsByCabinetActions = ProjectsByCabinetSlice.actions;
export const projectsByCabinetReducer = ProjectsByCabinetSlice.reducer;

export * as projectsByCabinetSelectors from "./selectors";
