import React, { useCallback, useEffect } from "react";
import { Panel } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Alert, Button, Form, FormProps, Input, Typography } from "antd";
import { TRegistrationRequest } from "../../../api/unauthorized";
import { MaskedInput } from "antd-mask-input";
import { Link } from "react-router-dom";
import {
  registerActions,
  registerSelectors,
} from "../../../store/user/register";
import { register } from "../../../store/user/register/thunk";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

export const Registration = () => {
  const dispatch = useAppDispatch();

  const { isLoading, error, email } = useAppSelector(
    registerSelectors.getState
  );

  const onRegister: FormProps<TRegistrationRequest>["onFinish"] = useCallback(
    (values: TRegistrationRequest) => {
      dispatch(register(values));
    },
    [dispatch]
  );

  useEffect(() => {
    return () => {
      dispatch(registerActions.clearState());
    };
  }, [dispatch]);

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      {email ? (
        <Alert
          message="Наш менеджер свяжется с Вами"
          description={`В ближайшее время на Вашу почту ${email} поступит письмо от нашего менеджера с инструкцией для продолжения регистрации.`}
          type="success"
          showIcon
        />
      ) : (
        <>
          <Typography.Title level={3} style={{ margin: 0 }}>
            Регистрация
          </Typography.Title>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            // style={{ maxWidth: 600 }}
            onFinish={onRegister}
            // onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelWrap
            colon={false}
          >
            <Form.Item<TRegistrationRequest>
              label="Фамилия"
              name="last_name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Введите фамилию",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item<TRegistrationRequest>
              label="Имя"
              name="first_name"
              rules={[
                { required: true, whitespace: true, message: "Введите имя" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item<TRegistrationRequest>
              label="Отчество"
              name="middle_name"
            >
              <Input />
            </Form.Item>

            <Form.Item<TRegistrationRequest>
              label="E-mail"
              name="email"
              rules={[
                { required: true, message: "Введите e-mail", type: "email" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item<TRegistrationRequest>
              label="Номер телефона"
              name="phone"
              rules={[
                {
                  pattern: new RegExp(
                    /^((8|\+7)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
                  ),
                  message: "Введите корректный номер",
                },
                {
                  required: true,
                  message: "Введите номер телефона",
                },
              ]}
            >
              <MaskedInput mask={"0(000)000-00-00"} />
            </Form.Item>

            <Form.Item<TRegistrationRequest>
              label="Название компании"
              name="company_name"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: "Введите название компании",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                Зарегистрироваться
              </Button>
            </Form.Item>

            <ErrorAlert error={error} />
          </Form>

          <Link to="/login">
            <Button type="link">Войти</Button>
          </Link>
        </>
      )}
    </Panel>
  );
};
