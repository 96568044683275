import { TOfferForSeller } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const getOfferForSellerById = (
  id: TGetOfferForSellerByIdRequest
): RequestPromise<TGetOfferForSellerByIdResponse> => {
  return apiRequest.get(`/offers-for-sellers/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TGetOfferForSellerByIdRequest = number;

export type TGetOfferForSellerByIdResponse = TOfferForSeller;
