import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute/ProtectedRoute";
import {
  Account,
  Accounts,
  Application,
  ApplicationForSeller,
  Applications,
  ApplicationsForSeller,
  Approvements,
  Colleague,
  Colleagues,
  Companies,
  Company,
  EmailPass,
  HomePage,
  Login,
  NotFound,
  OfferForBuyer,
  OfferForSeller,
  OffersForBuyer,
  OffersForSeller,
  Personal,
  Profile,
  Project,
  Projects,
  Registration,
  RestorePass,
} from "../../pages";

export const MainRouter = () => {
  const location = useLocation();

  return (
    <Routes location={location}>
      <Route
        path="/"
        element={
          <ProtectedRoute authRequired>
            <HomePage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/login"
        element={
          <ProtectedRoute>
            <Login />
          </ProtectedRoute>
        }
      />

      <Route
        path="/registration"
        element={
          <ProtectedRoute>
            <Registration />
          </ProtectedRoute>
        }
      />

      <Route
        path="/restorepass"
        element={
          <ProtectedRoute>
            <RestorePass />
          </ProtectedRoute>
        }
      />

      <Route path="/emailpass" element={<EmailPass />} />

      <Route
        path="/profile"
        element={
          <ProtectedRoute authRequired>
            <Profile />
          </ProtectedRoute>
        }
      />

      <Route
        path="/personal"
        element={
          <ProtectedRoute authRequired>
            <Personal />
          </ProtectedRoute>
        }
      />

      {/* Здесь внутри будет еще 4 роута new, account, edit, delete */}
      <Route path="/companies">
        <Route
          path=""
          element={
            <ProtectedRoute authRequired>
              <Companies />
            </ProtectedRoute>
          }
        />
        <Route path=":entity_id">
          <Route
            path=""
            element={
              <ProtectedRoute authRequired>
                <Company />
              </ProtectedRoute>
            }
          />
          <Route path="accounts">
            <Route
              path=""
              element={
                <ProtectedRoute authRequired>
                  <Accounts />
                </ProtectedRoute>
              }
            />
            <Route
              path=":account_id"
              element={
                <ProtectedRoute authRequired>
                  <Account />
                </ProtectedRoute>
              }
            />
          </Route>
        </Route>
      </Route>

      {/* Здесь внутри будет еще 2 роута new и edit */}
      <Route path="/colleagues">
        <Route
          path=""
          element={
            <ProtectedRoute authRequired>
              <Colleagues />
            </ProtectedRoute>
          }
        />
        <Route
          path=":user_id"
          element={
            <ProtectedRoute authRequired>
              <Colleague />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/applications">
        <Route
          path=""
          element={
            <ProtectedRoute authRequired>
              <Applications />
            </ProtectedRoute>
          }
        />
        <Route
          path=":application_id"
          element={
            <ProtectedRoute authRequired>
              <Application />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/availableapplications">
        <Route
          path=""
          element={
            <ProtectedRoute authRequired>
              <ApplicationsForSeller />
            </ProtectedRoute>
          }
        />
        <Route
          path=":application_id"
          element={
            <ProtectedRoute authRequired>
              <ApplicationForSeller />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/projects">
        <Route
          path=""
          element={
            <ProtectedRoute authRequired>
              <Projects />
            </ProtectedRoute>
          }
        />
        <Route
          path=":project_id"
          element={
            <ProtectedRoute authRequired>
              <Project />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route
        path="/accounts"
        element={
          <ProtectedRoute authRequired>
            <Accounts byCabinet />
          </ProtectedRoute>
        }
      />
      <Route
        path="/approvements"
        element={
          <ProtectedRoute authRequired>
            <Approvements />
          </ProtectedRoute>
        }
      />

      <Route path="/myoffers">
        <Route
          path=""
          element={
            <ProtectedRoute authRequired>
              <OffersForSeller />
            </ProtectedRoute>
          }
        />
        <Route
          path=":offer_id"
          element={
            <ProtectedRoute authRequired>
              <OfferForSeller />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="/offers">
        <Route
          path=""
          element={
            <ProtectedRoute authRequired>
              <OffersForBuyer />
            </ProtectedRoute>
          }
        />
        <Route
          path=":offer_id"
          element={
            <ProtectedRoute authRequired>
              <OfferForBuyer />
            </ProtectedRoute>
          }
        />
      </Route>

      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};
