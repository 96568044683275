import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TEntityByIdState } from "./types";

import { RequestError, TEntity } from "../../../types";

const initialState: TEntityByIdState = {
  isLoading: false,
  error: null,
  entity: undefined,
};

export const EntityByIdSlice = createSlice({
  name: "entity/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setEntity: (state, { payload }: PayloadAction<TEntity>) => {
      state.entity = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const entityByIdActions = EntityByIdSlice.actions;
export const entityByIdReducer = EntityByIdSlice.reducer;

export * as entityByIdSelectors from "./selectors";
