import React, { useCallback, useEffect, useMemo } from "react";
import { ApplicationsTable, EnlargedTag, OfferData } from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { userSelectors } from "../../../store/user";
import { CTABlock, Wrapper } from "./OfferForBuyer.styles";
import { DownOutlined } from "@ant-design/icons";
import { offerStatusIdMapper } from "../../../utils/mappers";
import { offerUpdateForBuyerStatusOptions } from "../../../constants/options";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";

import {
  offerForBuyerByIdActions,
  offerForBuyerByIdSelectors,
} from "../../../store/offersForBuyer/byId";
import {
  offerForBuyerUpdateStatusActions,
  offerForBuyerUpdateStatusSelectors,
} from "../../../store/offersForBuyer/update_status";
import {
  ApplicationByIdActions,
  applicationByIdSelectors,
} from "../../../store/applications/byId";
import { getOfferForBuyerById } from "../../../store/offersForBuyer/byId/thunk";
import { getApplicationById } from "../../../store/applications/byId/thunk";
import { getProjectsByCabinet } from "../../../store/projects/byCabinet/thunk";
import { projectsByCabinetSelectors } from "../../../store/projects/byCabinet";
import { updateOfferForBuyerStatus } from "../../../store/offersForBuyer/update_status/thunk";

export const OfferForBuyer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { user } = useAppSelector(userSelectors.getState);
  const { offerForBuyer } = useAppSelector(offerForBuyerByIdSelectors.getState);

  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForBuyerUpdateStatusSelectors.getState
  );
  const { application } = useAppSelector(applicationByIdSelectors.getState);
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);

  const { offer_id } = useParams<{ offer_id: string }>();

  const statusOptions: MenuProps["items"] = useMemo(
    () =>
      offerForBuyer
        ? (
            offerUpdateForBuyerStatusOptions[offerForBuyer?.status_id] || []
          ).map((status) => {
            const statusData = offerStatusIdMapper[status];
            return {
              key: status,
              label: (
                <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
              ),
            };
          })
        : [],
    [offerForBuyer]
  );

  const statusData = useMemo(
    () =>
      offerForBuyer?.status_id
        ? offerStatusIdMapper[offerForBuyer?.status_id]
        : undefined,
    [offerForBuyer]
  );

  const project = useMemo(
    () =>
      projects?.find(
        (project) => project?.project_id === application?.project_id
      ),
    [application?.project_id, projects]
  );

  const entity = useMemo(
    () =>
      user?.entities?.find(
        (entity) => entity?.entity?.entity_id === project?.entity_id
      ),
    [project, user]
  );

  const canChangeStatus = useMemo(() => {
    return (
      !!statusOptions?.length &&
      (application?.creator?.user_id === user?.user_id ||
        application?.responsible?.user_id === user?.user_id ||
        (entity?.role_id && entity?.role_id >= 3) ||
        (entity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
          user?.cabinet_role_id === 2))
    );
  }, [application, entity, statusOptions, user]);

  const getOffer = useCallback(() => {
    dispatch(getOfferForBuyerById(Number(offer_id)));
  }, [dispatch, offer_id]);

  const getApplication = useCallback(
    (application_id: number) => {
      return dispatch(getApplicationById(application_id));
    },
    [dispatch]
  );

  const getProjects = useCallback(() => {
    user?.cabinet && dispatch(getProjectsByCabinet(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      offerForBuyer &&
        dispatch(
          updateOfferForBuyerStatus({
            offer_id: offerForBuyer?.offer_id,
            new_status_id: Number(key),
          })
        )
          .unwrap()
          .then(() => {
            getOffer();
          });
    },
    [dispatch, getOffer, offerForBuyer]
  );

  useEffect(() => {
    if (user?.cabinet?.is_seller) {
      navigate("/myoffers");
    }
  }, [navigate, user]);

  useEffect(() => {
    offer_id && getOffer();
  }, [getOffer, offer_id]);

  useEffect(() => {
    offerForBuyer && getApplication(offerForBuyer?.application_id);
  }, [getApplication, offerForBuyer]);

  useEffect(() => {
    !projects && getProjects();
  }, [getProjects, projects]);

  useEffect(() => {
    return () => {
      dispatch(offerForBuyerUpdateStatusActions.clearState());
      dispatch(offerForBuyerByIdActions.clearState());
      dispatch(ApplicationByIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        Предложение #{offerForBuyer?.offer_id}
        {statusData ? (
          <Tooltip title={statusData?.description}>
            <EnlargedTag color={statusData?.color}>
              {statusData?.shortName}
            </EnlargedTag>
          </Tooltip>
        ) : (
          offerForBuyer?.status_id
        )}
      </Typography.Title>

      {offerForBuyer ? (
        <>
          {canChangeStatus && (
            <CTABlock>
              <Dropdown
                menu={{ items: statusOptions, onClick: onUpdateStatus }}
              >
                <Button
                  loading={statusIsLoading}
                  style={{ marginLeft: "auto" }}
                >
                  <Space>
                    Изменить статус
                    <DownOutlined />
                  </Space>
                </Button>
              </Dropdown>
            </CTABlock>
          )}

          <ErrorAlert error={statusError} />

          {application && (
            <ApplicationsTable
              applications={[application]}
              pagination={false}
            />
          )}

          <OfferData
            offer={offerForBuyer}
            application={application}
            entity={entity?.entity}
          />
        </>
      ) : (
        <Alert message="Данные по предложению отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
