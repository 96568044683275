import { createAsyncThunk } from "@reduxjs/toolkit";
import { offerForBuyerUpdateStatusActions } from ".";
import * as api from "../../../api/offers_for_buyers";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const updateOfferForBuyerStatus = createAsyncThunk<
  api.TUpdateOfferForBuyerStatusResponse,
  api.TUpdateOfferForBuyerStatusRequest
>(
  "offersForBuyer/updateOfferForBuyerStatus",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(offerForBuyerUpdateStatusActions.setLoading(true));
    dispatch(offerForBuyerUpdateStatusActions.setError(null));
    return api
      .updateOfferForBuyerStatus(payload)
      .then(({ data }) => {
        dispatch(offerForBuyerUpdateStatusActions.setOfferForBuyer(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(offerForBuyerUpdateStatusActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(offerForBuyerUpdateStatusActions.setLoading(false));
      });
  }
);
