import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Panel, ProjectEditForm } from "../../common";
import { userSelectors } from "../../../store/user";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CheckOutlined, CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Wrapper } from "./Projects.styles";
import { Button, List, Typography } from "antd";
import { Link } from "react-router-dom";
import { TProject } from "../../../types";
import { projectsByCabinetSelectors } from "../../../store/projects/byCabinet";
import {
  projectAddActions,
  projectAddSelectors,
} from "../../../store/projects/add";
import { getProjectsByCabinet } from "../../../store/projects/byCabinet/thunk";
import { addProject } from "../../../store/projects/add/thunk";

export const Projects = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { projects } = useAppSelector(projectsByCabinetSelectors.getState);
  const { isLoading: addIsLoading, error: addError } = useAppSelector(
    projectAddSelectors.getState
  );

  const [isAdding, setIsAdding] = useState(false);

  const projectsData = useMemo(
    () =>
      (projects || []).map((project) => ({
        value: project?.project_name,
        label: project?.project_description,
        icon: project?.is_active ? <CheckOutlined /> : <CloseOutlined />,
        id: project?.project_id,
      })),
    [projects]
  );

  const canAdd = useMemo(
    () =>
      (user?.entities?.some((entity) => entity?.role_id >= 3) &&
        user?.cabinet_role_id === 2) ||
      user?.is_admin,
    [user]
  );

  const getProjects = useCallback(() => {
    user?.cabinet && dispatch(getProjectsByCabinet(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const onAdd = useCallback(() => {
    setIsAdding(true);
  }, []);
  const onCancelAdd = useCallback(() => {
    setIsAdding(false);
  }, []);

  const onAddProject = useCallback(
    (values: TProject) => {
      dispatch(
        addProject({
          ...values,
          is_active: true,
        })
      )
        .unwrap()
        .then(() => {
          onCancelAdd();
          getProjects();
        });
    },
    [dispatch, getProjects, onCancelAdd]
  );

  useEffect(() => {
    !projects && getProjects();
  }, [dispatch, getProjects, projects]);

  useEffect(() => {
    return () => {
      dispatch(projectAddActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ marginTop: 0 }}>
        Проекты
      </Typography.Title>

      {canAdd && (
        <Button
          icon={isAdding ? <CloseOutlined /> : <PlusOutlined />}
          onClick={isAdding ? onCancelAdd : onAdd}
        >
          {isAdding ? "Отмена" : "Добавить"}
        </Button>
      )}

      <Panel
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        {isAdding ? (
          <ProjectEditForm
            entities={user?.entities || []}
            onSubmit={onAddProject}
            isLoading={addIsLoading}
            error={addError}
          />
        ) : (
          <List
            dataSource={projectsData}
            renderItem={(item) => (
              <Link to={`${item?.id}`}>
                <List.Item>
                  <List.Item.Meta
                    avatar={item?.icon}
                    title={item?.value}
                    description={item?.label}
                  />
                </List.Item>
              </Link>
            )}
          />
        )}
      </Panel>
    </Wrapper>
  );
};
