import { ComponentProps } from "react";
import { TApplicationApprovement } from "../../types";
import { Tag } from "antd";

export const applicationApprovementStatusIdMapper: Record<
  TApplicationApprovement["approvement_status_id"],
  {
    shortName: string;
    full_name: string;
    description: string;
    color: ComponentProps<typeof Tag>["color"];
  }
> = {
  108: {
    shortName: "На согласовании",
    full_name: "На согласовании",
    description: "Передано согласователю",
    color: "processing",
  },
  109: {
    shortName: "Согласовано",
    full_name: "Согласовано",
    description: "Согласователь подтвердил",
    color: "success",
  },
  110: {
    shortName: "Отклонено",
    full_name: "Отклонено",
    description: "Согласователь отклонил",
    color: "error",
  },
};
