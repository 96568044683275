import { combineReducers } from "@reduxjs/toolkit";
import { projectAddReducer } from "./add";
import { projectUpdateReducer } from "./update";
import { projectDeleteReducer } from "./delete";
import { projectsByCabinetReducer } from "./byCabinet";

export const projectsReducer = combineReducers({
  add: projectAddReducer,
  update: projectUpdateReducer,
  delete: projectDeleteReducer,
  byCabinet: projectsByCabinetReducer,
});
