import { TEntity } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const deleteEntity = (
  id: TDeleteEntityRequest
): RequestPromise<TDeleteEntityResponse> => {
  return apiRequest.post(`/entities/delete/${id}`, undefined, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TDeleteEntityRequest = number;

export type TDeleteEntityResponse = TEntity;
