import { createAsyncThunk } from "@reduxjs/toolkit";
import { colleagueDeleteActions } from ".";
import * as api from "../../../api/users";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const deleteColleague = createAsyncThunk<
  api.TDeleteUserResponse,
  api.TDeleteUserRequest
>("colleagues/deleteColleague", (payload, { dispatch, rejectWithValue }) => {
  dispatch(colleagueDeleteActions.setLoading(true));
  dispatch(colleagueDeleteActions.setError(null));
  return api
    .deleteUser(payload)
    .then(({ data }) => {
      dispatch(colleagueDeleteActions.setColleague(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(colleagueDeleteActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(colleagueDeleteActions.setLoading(false));
    });
});
