import { TProject } from "../../types";

export const projectRegionIdMapper: Record<
  TProject["region_id"],
  { shortName: number; fullName: string }
> = {
  8: { shortName: 1, fullName: "Республика Адыгея (Адыгея)" },
  9: { shortName: 2, fullName: "Республика Башкортостан" },
  10: { shortName: 3, fullName: "Республика Бурятия" },
  11: { shortName: 4, fullName: "Республика Алтай" },
  12: { shortName: 5, fullName: "Республика Дагестан" },
  13: { shortName: 6, fullName: "Республика Ингушетия" },
  14: { shortName: 7, fullName: "Кабардино-Балкарская Республика" },
  15: { shortName: 8, fullName: "Республика Калмыкия" },
  16: { shortName: 9, fullName: "Карачаево-Черкесская Республика" },
  17: { shortName: 10, fullName: "Республика Карелия" },
  18: { shortName: 11, fullName: "Республика Коми" },
  19: { shortName: 12, fullName: "Республика Марий Эл" },
  20: { shortName: 13, fullName: "Республика Мордовия" },
  21: { shortName: 14, fullName: "Республика Саха (Якутия)" },
  22: { shortName: 15, fullName: "Республика Северная Осетия - Алания" },
  23: { shortName: 16, fullName: "Республика Татарстан (Татарстан)" },
  24: { shortName: 17, fullName: "Республика Тыва" },
  25: { shortName: 18, fullName: "Удмуртская Республика" },
  26: { shortName: 19, fullName: "Республика Хакасия" },
  27: { shortName: 20, fullName: "Чеченская Республика" },
  28: { shortName: 21, fullName: "Чувашская Республика - Чувашия" },
  29: { shortName: 22, fullName: "Алтайский край" },
  30: { shortName: 23, fullName: "Краснодарский край" },
  31: { shortName: 24, fullName: "Красноярский край" },
  32: { shortName: 25, fullName: "Приморский край" },
  33: { shortName: 26, fullName: "Ставропольский край" },
  34: { shortName: 27, fullName: "Хабаровский край" },
  35: { shortName: 28, fullName: "Амурская область" },
  36: { shortName: 29, fullName: "Архангельская область" },
  37: { shortName: 30, fullName: "Астраханская область" },
  38: { shortName: 31, fullName: "Белгородская область" },
  39: { shortName: 32, fullName: "Брянская область" },
  40: { shortName: 33, fullName: "Владимирская область" },
  41: { shortName: 34, fullName: "Волгоградская область" },
  42: { shortName: 35, fullName: "Вологодская область" },
  43: { shortName: 36, fullName: "Воронежская область" },
  44: { shortName: 37, fullName: "Ивановская область" },
  45: { shortName: 38, fullName: "Иркутская область" },
  46: { shortName: 39, fullName: "Калининградская область" },
  47: { shortName: 40, fullName: "Калужская область" },
  48: { shortName: 41, fullName: "Камчатский край" },
  49: { shortName: 42, fullName: "Кемеровская область" },
  50: { shortName: 43, fullName: "Кировская область" },
  51: { shortName: 44, fullName: "Костромская область" },
  52: { shortName: 45, fullName: "Курганская область" },
  53: { shortName: 46, fullName: "Курская область" },
  54: { shortName: 47, fullName: "Ленинградская область" },
  55: { shortName: 48, fullName: "Липецкая область" },
  56: { shortName: 49, fullName: "Магаданская область" },
  57: { shortName: 50, fullName: "Московская область" },
  58: { shortName: 51, fullName: "Мурманская область" },
  59: { shortName: 52, fullName: "Нижегородская область" },
  60: { shortName: 53, fullName: "Новгородская область" },
  61: { shortName: 54, fullName: "Новосибирская область" },
  62: { shortName: 55, fullName: "Омская область" },
  63: { shortName: 56, fullName: "Оренбургская область" },
  64: { shortName: 57, fullName: "Орловская область" },
  65: { shortName: 58, fullName: "Пензенская область" },
  66: { shortName: 59, fullName: "Пермский край" },
  67: { shortName: 60, fullName: "Псковская область" },
  68: { shortName: 61, fullName: "Ростовская область" },
  69: { shortName: 62, fullName: "Рязанская область" },
  70: { shortName: 63, fullName: "Самарская область" },
  71: { shortName: 64, fullName: "Саратовская область" },
  72: { shortName: 65, fullName: "Сахалинская область" },
  73: { shortName: 66, fullName: "Свердловская область" },
  74: { shortName: 67, fullName: "Смоленская область" },
  75: { shortName: 68, fullName: "Тамбовская область" },
  76: { shortName: 69, fullName: "Тверская область" },
  77: { shortName: 70, fullName: "Томская область" },
  78: { shortName: 71, fullName: "Тульская область" },
  79: { shortName: 72, fullName: "Тюменская область" },
  80: { shortName: 73, fullName: "Ульяновская область" },
  81: { shortName: 74, fullName: "Челябинская область" },
  82: { shortName: 75, fullName: "Забайкальский край" },
  83: { shortName: 76, fullName: "Ярославская область" },
  84: { shortName: 77, fullName: "г. Москва" },
  85: { shortName: 78, fullName: "Санкт-Петербург" },
  86: { shortName: 79, fullName: "Еврейская автономная область" },
  87: { shortName: 83, fullName: "Ненецкий автономный округ" },
  88: { shortName: 86, fullName: "Ханты-Мансийский автономный округ - Югра" },
  89: { shortName: 87, fullName: "Чукотский автономный округ" },
  90: { shortName: 89, fullName: "Ямало-Ненецкий автономный округ" },
  91: {
    shortName: 99,
    fullName: "Иные территории, включая город и космодром Байконур",
  },
};
