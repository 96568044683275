import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAccountAddState } from "./types";
import { RequestError, TAccount } from "../../../types";

const initialState: TAccountAddState = {
  isLoading: false,
  error: null,
  account: undefined,
};

export const AccountAddSlice = createSlice({
  name: "account/add",
  initialState,
  reducers: {
    clearState: () => initialState,
    setAccount: (state, { payload }: PayloadAction<TAccount>) => {
      state.account = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const accountAddActions = AccountAddSlice.actions;
export const accountAddReducer = AccountAddSlice.reducer;

export * as accountAddSelectors from "./selectors";
