import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOfferForSellerUpdateState } from "./types";

import { RequestError, TOfferForSeller } from "../../../types";

const initialState: TOfferForSellerUpdateState = {
  isLoading: false,
  error: null,
  offerForSeller: undefined,
};

export const OfferForSellerUpdateSlice = createSlice({
  name: "offersForSeller/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOfferForSeller: (state, { payload }: PayloadAction<TOfferForSeller>) => {
      state.offerForSeller = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const offerForSellerUpdateActions = OfferForSellerUpdateSlice.actions;
export const offerForSellerUpdateReducer = OfferForSellerUpdateSlice.reducer;

export * as offerForSellerUpdateSelectors from "./selectors";
