import { createAsyncThunk } from "@reduxjs/toolkit";
import { projectAddActions } from "./add";
import { projectsByCabinetActions } from "./byCabinet";
import { projectDeleteActions } from "./delete";
import { projectUpdateActions } from "./update";

export const clearProjects = createAsyncThunk<void, void>(
  "project/clearProjects",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(projectAddActions.clearState());
    dispatch(projectsByCabinetActions.clearState());
    dispatch(projectDeleteActions.clearState());
    dispatch(projectUpdateActions.clearState());
  }
);
