import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TSetPasswordState } from "./types";

import { RequestError } from "../../../types";

const initialState: TSetPasswordState = {
  isLoading: false,
  error: null,
  success: false,
};

export const SetPasswordSlice = createSlice({
  name: "user/setPassword",
  initialState,
  reducers: {
    clearState: () => initialState,
    setSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.success = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const setPasswordActions = SetPasswordSlice.actions;
export const setPasswordReducer = SetPasswordSlice.reducer;

export * as setPasswordSelectors from "./selectors";
