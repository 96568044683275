import { createAsyncThunk } from "@reduxjs/toolkit";
import { projectsByCabinetActions } from ".";
import * as api from "../../../api/project";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const getProjectsByCabinet = createAsyncThunk<
  api.TGetProjectsByCabinetResponse,
  api.TGetProjectsByCabinetRequest
>("project/getProjectsByCabinet", (payload, { dispatch, rejectWithValue }) => {
  dispatch(projectsByCabinetActions.setLoading(true));
  dispatch(projectsByCabinetActions.setError(null));
  return api
    .getProjectsByCabinet(payload)
    .then(({ data }) => {
      dispatch(
        projectsByCabinetActions.setProjects(data)
      );
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(projectsByCabinetActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(projectsByCabinetActions.setLoading(false));
    });
});
