import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationByIdState } from "./types";

import { RequestError, TApplication } from "../../../types";



const initialState: TApplicationByIdState = {
  isLoading: false,
  error: null,
  application: undefined,
};

export const ApplicationByIdSlice = createSlice({
  name: "applications/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApplication: (
      state,
      { payload }: PayloadAction<TApplication>
    ) => {
      state.application = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const ApplicationByIdActions = ApplicationByIdSlice.actions;
export const ApplicationByIdReducer = ApplicationByIdSlice.reducer;

export * as applicationByIdSelectors from "./selectors";
