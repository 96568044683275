import { combineReducers } from "@reduxjs/toolkit";
import { accountAddReducer } from "./add";
import { accountUpdateReducer } from "./update";
import { accountDeleteReducer } from "./delete";
import { accountByEntityIdReducer } from "./byEntity";
import { accountByCabinetIdReducer } from "./byCabinet";

export const accountsReducer = combineReducers({
  add: accountAddReducer,
  update: accountUpdateReducer,
  delete: accountDeleteReducer,
  byEntityId: accountByEntityIdReducer,
  byCabinetId: accountByCabinetIdReducer,
});
