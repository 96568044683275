import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TApplicationsForSellerGetState } from "./types";

import { RequestError, TApplicationForSeller } from "../../../types";

const initialState: TApplicationsForSellerGetState = {
  isLoading: false,
  error: null,
  applications: undefined,
  totalElements: undefined,
  page: 1,
  size: 20,
  search: undefined,
};

export const ApplicationsForSellerGetSlice = createSlice({
  name: "applicationForSeller/get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setApplications: (state, { payload }: PayloadAction<TApplicationForSeller[]>) => {
      state.applications = payload;
    },
    setTotalElements: (state, { payload }: PayloadAction<number>) => {
      state.totalElements = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string | undefined>) => {
      state.search = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const applicationsForSellerGetActions = ApplicationsForSellerGetSlice.actions;
export const applicationsForSellerGetReducer = ApplicationsForSellerGetSlice.reducer;

export * as applicationsForSellerGetSelectors from "./selectors";
