import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAccountUpdateState } from "./types";

import { RequestError, TAccount } from "../../../types";

const initialState: TAccountUpdateState = {
  isLoading: false,
  error: null,
  account: undefined,
};

export const AccountUpdateSlice = createSlice({
  name: "account/update",
  initialState,
  reducers: {
    clearState: () => initialState,
    setAccount: (state, { payload }: PayloadAction<TAccount>) => {
      state.account = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const accountUpdateActions = AccountUpdateSlice.actions;
export const accountUpdateReducer = AccountUpdateSlice.reducer;

export * as accountUpdateSelectors from "./selectors";
