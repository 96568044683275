import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOfferForSellerByIdState } from "./types";

import { RequestError, TOfferForSeller } from "../../../types";

const initialState: TOfferForSellerByIdState = {
  isLoading: false,
  error: null,
  offerForSeller: undefined,
};

export const OfferForSellerByIdSlice = createSlice({
  name: "offersForSeller/byId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOfferForSeller: (state, { payload }: PayloadAction<TOfferForSeller>) => {
      state.offerForSeller = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const offerForSellerByIdActions = OfferForSellerByIdSlice.actions;
export const offerForSellerByIdReducer = OfferForSellerByIdSlice.reducer;

export * as offerForSellerByIdSelectors from "./selectors";
