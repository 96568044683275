import React, { FC, useCallback, useMemo } from "react";
import { Panel } from "..";
import { Button, Form, Input } from "antd";
import { RequestError, TEntity, TPerson } from "../../../types";
import { EntitiesSelector } from "./components";
import { entitiesByCabinetIdSelectors } from "../../../store/entities/byCabinetId";
import { useAppSelector } from "../../../store/store";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";

type TUserEditFormProps = {
  user?: TPerson;
  curUser?: TPerson;
  onSubmit: (values: TPerson) => void;
  isLoading: boolean;
  error: RequestError | null;
};

export const UserEditForm: FC<TUserEditFormProps> = ({
  user,
  curUser,
  onSubmit,
  isLoading,
  error,
}) => {
  const { entities } = useAppSelector(entitiesByCabinetIdSelectors.getState);

  const initialValues = useMemo(
    () =>
      user
        ? {
            ...user,
            entities: user?.entities?.map(({ entity, role_id }) => ({
              role_id,
              entity: entity?.entity_id,
            })),
          }
        : undefined,
    [user]
  );

  const onSubmitHandler = useCallback(
    (user: TPerson) => {
      onSubmit({
        ...user,
        entities: user?.entities?.map(({ entity: entityId, role_id }) => ({
          role_id,
          entity: entities?.find(
            (entity) => entity?.entity_id === (entityId as unknown as number)
          ) as TEntity,
        })),
      });
    },
    [entities, onSubmit]
  );

  return (
    <Panel
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 28 }}
        // style={{ maxWidth: "600px" }}
        onFinish={onSubmitHandler}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelWrap
        colon={false}
        initialValues={initialValues}
      >
        <Form.Item<TPerson>
          label="Фамилия"
          name="last_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите фамилию",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TPerson>
          label="Имя"
          name="first_name"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите имя",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TPerson> label="Отчество" name="middle_name">
          <Input />
        </Form.Item>

        <Form.Item<TPerson>
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              message: "Введите корректный e-mail",
              type: "email",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TPerson>
          label="Телефон"
          name="phone"
          rules={[
            {
              required: true,
              pattern: new RegExp(
                /^((8|\+7)[- ]?)(\(?\d{3}\)?[- ]?)?[\d\- ]{7,10}$/
              ),
              message: "Введите корректный номер",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TPerson>
          label="Логин"
          name="login"
          rules={[
            {
              required: true,
              whitespace: true,
              message: "Введите логин",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item<TPerson>
          label="Должность"
          name="position"
          rules={[
            {
              whitespace: true,
              message: "Введите должность",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {curUser && <EntitiesSelector user={curUser} />}

        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Сохранить
          </Button>
        </Form.Item>

        <ErrorAlert error={error} />
      </Form>
    </Panel>
  );
};
