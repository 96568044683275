import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TAccountDeleteState } from "./types";

import { RequestError, TAccount } from "../../../types";

const initialState: TAccountDeleteState = {
  isLoading: false,
  error: null,
  account: undefined,
};

export const AccountDeleteSlice = createSlice({
  name: "account/delete",
  initialState,
  reducers: {
    clearState: () => initialState,
    setAccount: (state, { payload }: PayloadAction<TAccount>) => {
      state.account = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const accountDeleteActions = AccountDeleteSlice.actions;
export const accountDeleteReducer = AccountDeleteSlice.reducer;

export * as accountDeleteSelectors from "./selectors";
