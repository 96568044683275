import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TRestorePasswordState } from "./types";
import { RequestError } from "../../../types";

const initialState: TRestorePasswordState = {
  isLoading: false,
  error: null,
  email: undefined,
};

export const RestorePasswordSlice = createSlice({
  name: "user/restorePassword",
  initialState,
  reducers: {
    clearState: () => initialState,
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const restorePasswordActions = RestorePasswordSlice.actions;
export const restorePasswordReducer = RestorePasswordSlice.reducer;

export * as restorePasswordSelectors from "./selectors";
