import React, { useCallback, useEffect } from "react";
import { ApprovementsTable } from "../../common";
import { userSelectors } from "../../../store/user";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { Wrapper } from "./Approvements.styles";
import {  Typography } from "antd";
import { getApprovementsByUserId } from "../../../store/approvements/byUserId/thunk";
import {
  approvementsByUserIdActions,
  approvementsByUserIdSelectors,
} from "../../../store/approvements/byUserId";
import {
  approvementUpdateActions,
  approvementUpdateSelectors,
} from "../../../store/approvements/update";
import { updateApprovement } from "../../../store/approvements/update/thunk";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";


export const Approvements = () => {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector(userSelectors.getState);
  const { approvements } = useAppSelector(
    approvementsByUserIdSelectors.getState
  );
  const { isLoading, error } = useAppSelector(
    approvementUpdateSelectors.getState
  );

  const getApprovements = useCallback(() => {
    user && dispatch(getApprovementsByUserId(user?.user_id));
  }, [dispatch, user]);

  const onUpdate = useCallback(
    (approvement_id: number, approvement_status_id: number) => {
      dispatch(updateApprovement({ approvement_id, approvement_status_id }))
        .unwrap()
        .then(() => {
          getApprovements();
        });
    },
    [dispatch, getApprovements]
  );

  useEffect(() => {
    getApprovements();
  }, [dispatch, getApprovements]);

  useEffect(() => {
    return () => {
      dispatch(approvementsByUserIdActions.clearState());
      dispatch(approvementUpdateActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title level={3} style={{ marginTop: 0 }}>
        Согласования
      </Typography.Title>

      <ErrorAlert error={error} />

      <ApprovementsTable
        approvements={approvements}
        onUpdate={onUpdate}
        isLoading={isLoading}
      />
    </Wrapper>
  );
};
