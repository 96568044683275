import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOffersForBuyerGetState } from "./types";

import { RequestError, TOfferForBuyer } from "../../../types";

const initialState: TOffersForBuyerGetState = {
  isLoading: false,
  error: null,
  offersForBuyer: undefined,
  totalElements: undefined,
  page: 1,
  size: 20,
  search: undefined,
};

export const OffersForBuyerGetSlice = createSlice({
  name: "offersForBuyer/get",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOffersForBuyer: (state, { payload }: PayloadAction<TOfferForBuyer[]>) => {
      state.offersForBuyer = payload;
    },
    setTotalElements: (state, { payload }: PayloadAction<number>) => {
      state.totalElements = payload;
    },
    setPage: (state, { payload }: PayloadAction<number>) => {
      state.page = payload;
    },
    setSize: (state, { payload }: PayloadAction<number>) => {
      state.size = payload;
    },
    setSearch: (state, { payload }: PayloadAction<string | undefined>) => {
      state.search = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const offersForBuyerGetActions = OffersForBuyerGetSlice.actions;
export const offersForBuyerGetReducer = OffersForBuyerGetSlice.reducer;

export * as offersForBuyerGetSelectors from "./selectors";
