import { applicationApprovementStatusIdMapper } from "../../utils/mappers";

export const applicationApprovementStatusIdOptions = Object.keys(
  applicationApprovementStatusIdMapper
)
  .map(Number)
  .map((item) => ({
    value: item,
    label: applicationApprovementStatusIdMapper[item]?.shortName,
  }));
