import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOffersForBuyerByApplicationIdState } from "./types";

import { RequestError, TOfferForBuyer } from "../../../types";

const initialState: TOffersForBuyerByApplicationIdState = {
  isLoading: false,
  error: null,
  offersForBuyer: undefined,
};

export const OffersForBuyerByApplicationIdSlice = createSlice({
  name: "offersForBuyer/ByApplicationId",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOffersForBuyer: (state, { payload }: PayloadAction<TOfferForBuyer[]>) => {
      state.offersForBuyer = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const offersForBuyerByApplicationIdActions = OffersForBuyerByApplicationIdSlice.actions;
export const offersForBuyerByApplicationIdReducer = OffersForBuyerByApplicationIdSlice.reducer;

export * as offersForBuyerByApplicationIdSelectors from "./selectors";
