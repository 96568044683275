import { combineReducers } from "@reduxjs/toolkit";
import { offerForSellerByIdReducer } from "./byId";
import { offerForSellerAddReducer } from "./add";
import { offerForSellerUpdateReducer } from "./update";
import { offersForSellerGetReducer } from "./get";
import { offerForSellerUpdateStatusReducer } from "./update_status";
import { offersForSellerByApplicationIdReducer } from "./byApplicationId";

export const offersForSellerReducer = combineReducers({
  byId: offerForSellerByIdReducer,
  byApplicationId: offersForSellerByApplicationIdReducer,
  add: offerForSellerAddReducer,
  update: offerForSellerUpdateReducer,
  get: offersForSellerGetReducer,
  updateStatus: offerForSellerUpdateStatusReducer,
});
