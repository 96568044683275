import { TAccount } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const deleteAccount = (
  id: TDeleteAccountRequest
): RequestPromise<TDeleteAccountResponse> => {
  return apiRequest.post(`/accounts/delete/${id}`, undefined, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TDeleteAccountRequest = number;

export type TDeleteAccountResponse = TAccount;
