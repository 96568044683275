import React, { FC, useCallback, useMemo, useState } from "react";
import {
  TApplication,
  TEntity,
  TOfferForBuyer,
  TOfferForSeller,
} from "../../../types";
import {
  CalendarOutlined,
  ShopOutlined,
  GlobalOutlined,
  PushpinOutlined,
  EllipsisOutlined,
  CreditCardOutlined,
  ClockCircleOutlined,
  UserOutlined,
  InfoOutlined,
  InboxOutlined,
} from "@ant-design/icons";
import { Typography, List, Card, Modal, Button, Alert } from "antd";
import { Panel } from "../Panel/Panel";
import { Wrapper } from "./OfferData.styles";
import { toDisplayDateTime } from "../../../utils/dates";
import { projectRegionIdMapper } from "../../../utils/mappers";
import { AccountData } from "../AccountData/AccountData";
import { CompanyData } from "../CompanyData/CompanyData";
import { OfferPositionsTable } from "./components";
import { countPrice } from "../../../utils/countPrice";
import { padFractionalZeros, splitThousands } from "../../../utils/numbers";

type TOfferDataProps = {
  offer: TOfferForSeller | TOfferForBuyer;
  application?: TApplication;
  entity?: TEntity;
  forSeller?: boolean;
};

export const OfferData: FC<TOfferDataProps> = ({
  offer,
  application,
  entity,
  forSeller,
}) => {
  const [isAccountModalOpen, setIsAccountModalOpen] = useState(false);
  const [isEntityModalOpen, setIsEntityModalOpen] = useState(false);

  const openAccountModal = useCallback(() => {
    setIsAccountModalOpen(true);
  }, []);
  const closeAccountModal = useCallback(() => {
    setIsAccountModalOpen(false);
  }, []);
  const openEntityModal = useCallback(() => {
    setIsEntityModalOpen(true);
  }, []);
  const closeEntityModal = useCallback(() => {
    setIsEntityModalOpen(false);
  }, []);

  const generalData = useMemo(
    () => [
      {
        label: "Предложение",
        value: `#${offer?.offer_id}`,
        icon: <InboxOutlined />,
      },
      {
        label: "Дата создания предложения",
        value: toDisplayDateTime(offer?.create_dt),
        icon: <CalendarOutlined />,
      },
      {
        label: "Юр. лицо поставщика",
        value: offer?.seller_entity?.full_name,
        icon: <ShopOutlined />,
      },
      {
        label: "Счёт поставщика",
        value: offer?.seller_account?.name,
        icon: <CreditCardOutlined />,
      },
      {
        label: "Юр. лицо покупателя",
        value: forSeller
          ? (offer as TOfferForSeller)?.buyer_entity?.full_name
          : entity?.full_name,
        icon: <ShopOutlined />,
        additionalContent: (
          <Button shape="circle" icon={<InfoOutlined />} size="small" />
        ),
        onClick: forSeller ? openEntityModal : undefined,
      },
      {
        label: "Счёт покупателя",
        value: forSeller
          ? (offer as TOfferForSeller)?.buyer_account?.name
          : application?.buyer_account?.name,
        icon: <CreditCardOutlined />,
        additionalContent: (
          <Button shape="circle" icon={<InfoOutlined />} size="small" />
        ),
        onClick: forSeller ? openAccountModal : undefined,
      },
      {
        label: "Регион",
        value:
          projectRegionIdMapper[offer?.offer_region_id]?.fullName ||
          offer?.offer_region_id,
        icon: <GlobalOutlined />,
      },
      {
        label: "Необходима доставка",
        value: offer?.is_need_delivery
          ? offer?.delivery_address || "Да"
          : "Нет",
        icon: <PushpinOutlined />,
      },
      {
        label: "Необходим отложенный платеж",
        value: offer?.is_postpayment
          ? offer?.postpayment_days
            ? `${offer?.postpayment_days} дней`
            : "Да"
          : "Нет",
        icon: <ClockCircleOutlined />,
      },
      {
        label: "Комментарий к предложению",
        value: offer?.offer_comment,
        icon: <EllipsisOutlined />,
      },
    ],
    [application, entity, forSeller, offer, openAccountModal, openEntityModal]
  );

  const creatorsData = useMemo(
    () => [
      {
        label: "Создатель предложения",
        value: offer?.creator?.full_name,
        icon: <UserOutlined />,
      },
    ],
    [offer]
  );

  const { price, priceVAT } = useMemo(
    () =>
      (offer?.positions || [])?.reduce(
        (acc, { unit_amount, unit_price, discount, vat }) => {
          const price = countPrice(unit_amount, unit_price, discount, 0);
          const priceVAT = countPrice(unit_amount, unit_price, discount, vat);
          return {
            price: acc.price + price,
            priceVAT: acc.priceVAT + priceVAT,
          };
        },
        { price: 0, priceVAT: 0 }
      ),
    [offer]
  );

  return (
    <>
      <Wrapper>
        <Panel
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Параметры предложения
          </Typography.Title>
          <List
            grid={{
              gutter: [24, 0],
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
              xl: 3,
              xxl: 3,
            }}
            dataSource={generalData}
            renderItem={(item) => (
              <List.Item>
                <Card
                  size="small"
                  title={item?.label}
                  extra={item?.additionalContent}
                  onClick={item?.onClick}
                  hoverable={!!item?.onClick}
                >
                  <Card.Meta avatar={item?.icon} title={item?.value} />
                </Card>
              </List.Item>
            )}
          />
        </Panel>

        <Panel
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Позиции предложения
          </Typography.Title>
          <OfferPositionsTable positions={offer?.positions} />

          <Alert
            message={`Стоимость: ${splitThousands(
              padFractionalZeros(priceVAT)
            )}`}
            description={`Стоимость без НДС: ${splitThousands(
              padFractionalZeros(price)
            )}`}
            type="info"
          />
        </Panel>

        <Panel
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Title level={3} style={{ marginTop: 0 }}>
            Процесс предложения
          </Typography.Title>
          <List
            dataSource={creatorsData}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={item?.icon}
                  title={item?.value}
                  description={item?.label}
                />
              </List.Item>
            )}
          />
        </Panel>
      </Wrapper>
      <Modal
        title="Счёт покупателя"
        open={isAccountModalOpen}
        onCancel={closeAccountModal}
        footer={null}
      >
        <AccountData
          account={(offer as TOfferForSeller)?.buyer_account}
          isSmall
          withCopy
        />
      </Modal>
      <Modal
        title="Юр. лицо покупателя"
        open={isEntityModalOpen}
        onCancel={closeEntityModal}
        footer={null}
      >
        <CompanyData
          entity={(offer as TOfferForSeller)?.buyer_entity}
          isSmall
          withCopy
        />
      </Modal>
    </>
  );
};
