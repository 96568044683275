// import { MOCKED_LOGIN } from "./mock";
import { TPerson } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";

export const login = (data: TLoginRequest): RequestPromise<TLoginResponse> => {
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({ data: MOCKED_LOGIN });
  //   }, 1000);
  // }) as never;

  return apiRequest.post(`/unauthorized/login`, data);
};

export type TLoginResponse = {
  accessToken: string;
  user: TPerson;
};

export type TLoginRequest = {
  login: string;
  password: string;
};
