import React, { FC, useMemo } from "react";

import { Form, Input, Select, Typography } from "antd";
import { applicationApprovementTypeIdOptions } from "../../../../../constants/options";
import { TPerson } from "../../../../../types";

type TApprovementsSelectorProps = {
  creator?: TPerson;
  users?: TPerson[];
};

export const ApprovementsSelector: FC<TApprovementsSelectorProps> = ({
  creator,
  users,
}) => {
  const usersOptions = useMemo(
    () =>
      users?.map((user) => ({
        label: user?.full_name,
        value: user?.user_id,
      })),
    [users]
  );

  return (
    <>
      <Typography.Title level={4}>Процесс заявки</Typography.Title>

      <Form.Item label="Создатель заявки">
        <Input value={creator?.full_name} disabled />
      </Form.Item>

      <Form.Item
        label="Ответственный по заявке"
        name="responsible"
        rules={[
          {
            required: true,
            message: "Выберите ответственного по заявке",
          },
        ]}
      >
        <Select options={usersOptions} />
      </Form.Item>

      <Form.List name="approvements">
        {(fields) => {
          return (
            <>
              {fields.map(({ key, name, ...restField }, index, self) => (
                <div
                  key={key}
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "baseline",
                    marginBottom: 24,
                  }}
                >
                  <Form.Item
                    {...restField}
                    label={!index ? "Тип согласования" : undefined}
                    name={[name, "approvement_type_id"]}
                    labelCol={{ span: 24 }}
                    style={{ flex: 1, marginBottom: 0, maxWidth: 200 }}
                    rules={[
                      {
                        required: true,
                        message: "Введите тип согласования",
                      },
                    ]}
                  >
                    <Select
                      disabled
                      options={applicationApprovementTypeIdOptions}
                    />
                  </Form.Item>

                  <Form.Item
                    {...restField}
                    label={!index ? "Согласующий" : undefined}
                    name={[name, "approver"]}
                    labelCol={{ span: 24 }}
                    style={{ flex: 1, marginBottom: 0, maxWidth: 200 }}
                    rules={[
                      {
                        required: true,
                        message: "Выберите согласующего",
                      },
                    ]}
                  >
                    <Select disabled options={usersOptions} />
                  </Form.Item>
                </div>
              ))}
            </>
          );
        }}
      </Form.List>
    </>
  );
};
