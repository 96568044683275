import React, { FC, useMemo } from "react";
import {
  TApplicationPosition,
  TOfferForBuyer,
  TOfferForSeller,
  TPerson,
} from "../../../types";
import { Table, Tag } from "antd";
import { offerStatusIdMapper } from "../../../utils/mappers";
import { ColumnsType, TablePaginationConfig } from "antd/es/table";
import { toDisplayDate } from "../../../utils/dates";
import { Link } from "react-router-dom";
import { OffersTableExpandableRow } from "./components";

type TOffersTableProps = {
  offers?: TOfferForSeller[] | TOfferForBuyer[];
  pagination?: TablePaginationConfig | false;
  withoutApplication?: boolean;
  forSeller?: boolean;
};

export const OffersTable: FC<TOffersTableProps> = ({
  offers,
  pagination,
  withoutApplication,
  forSeller,
}) => {
  const columns: ColumnsType<TOfferForSeller | TOfferForBuyer> = useMemo(
    () => [
      {
        title: "Предложение",
        key: "offer_id",
        render: (value: TOfferForSeller) => (
          <Link
            to={`${forSeller ? "/myoffers" : "/offers"}/${value?.offer_id}`}
          >
            #{value?.offer_id}{" "}
            {value?.create_dt && `от ${toDisplayDate(value?.create_dt)}`}
          </Link>
        ),
      },
      {
        title: "Статус",
        dataIndex: "status_id",
        render: (value: number) => {
          const statusData = offerStatusIdMapper[value];
          return statusData ? (
            <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
          ) : (
            value
          );
        },
      },
      ...(!withoutApplication
        ? [
            {
              title: "Заявка",
              dataIndex: "application_id",
              render: (value: number) => (
                <Link
                  to={`${
                    forSeller ? "/availableapplications" : "/applications"
                  }/${value}`}
                >
                  #{value}
                </Link>
              ),
            },
          ]
        : []),
      {
        title: "Создатель",
        dataIndex: "creator",
        render: (value: TPerson) => value?.full_name,
      },
      {
        title: "Кол-во позиций",
        dataIndex: "positions",
        render: (value: TApplicationPosition[]) => value?.length,
      },
    ],
    [forSeller, withoutApplication]
  );

  return (
    <Table
      dataSource={offers}
      columns={columns}
      pagination={pagination}
      scroll={{ x: "1400px" }}
      expandable={{
        expandedRowRender: (row) => <OffersTableExpandableRow offer={row} />,
      }}
      rowKey={(row) => row?.offer_id}
    />
  );
};
