import { createAsyncThunk } from "@reduxjs/toolkit";
import { userActions } from ".";
import * as api from "../../api/users/get";
import { RequestError } from "../../types";
import { serializeError } from "serialize-error";
import { loginActions } from "./login";
import { logoutActions } from "./logout";
import { registerActions } from "./register";
import { restorePasswordActions } from "./restorePassword";
import { setPasswordActions } from "./setPassword";

export const getUser = createAsyncThunk<api.TGetUserResponse, void>(
  "user",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(userActions.setLoading(true));
    dispatch(userActions.setError(null));
    return api
      .getUser()
      .then(({ data }) => {
        dispatch(userActions.setUser(data));
        return data;
      })
      .catch((err: RequestError) => {
        dispatch(userActions.setError(err));
        return rejectWithValue(serializeError(err));
      })
      .finally(() => {
        dispatch(userActions.setLoading(false));
      });
  }
);

export const clearUser = createAsyncThunk<void, void>(
  "user/clearUser",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(userActions.clearState());
    dispatch(loginActions.clearState());
    dispatch(logoutActions.clearState());
    dispatch(registerActions.clearState());
    dispatch(restorePasswordActions.clearState());
    dispatch(setPasswordActions.clearState());
  }
);
