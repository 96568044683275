import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TRegisterState } from "./types";
import { RequestError } from "../../../types";


const initialState: TRegisterState = {
  isLoading: false,
  error: null,
  email: undefined,
};

export const RegisterSlice = createSlice({
  name: "user/register",
  initialState,
  reducers: {
    clearState: () => initialState,
    setEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const registerActions = RegisterSlice.actions;
export const registerReducer = RegisterSlice.reducer;

export * as registerSelectors from "./selectors";
