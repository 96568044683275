import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApplicationsTable,
  EnlargedTag,
  OfferEditForm,
  OfferData,
} from "../../common";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  Alert,
  Button,
  Dropdown,
  MenuProps,
  Space,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { userSelectors } from "../../../store/user";
import { CTABlock, Wrapper } from "./OfferForSeller.styles";
import { TOfferForSellerRequest } from "../../../types";
import { CloseOutlined, DownOutlined, EditOutlined } from "@ant-design/icons";
import { offerStatusIdMapper } from "../../../utils/mappers";
import { getAccountsByCabinetId } from "../../../store/accounts/byCabinet/thunk";
import { accountsByCabinetIdSelectors } from "../../../store/accounts/byCabinet";
import { offerUpdateForSellerStatusOptions } from "../../../constants/options";
import { ErrorAlert } from "../../common/ErrorAlert/ErrorAlert";
import {
  offerForSellerByIdActions,
  offerForSellerByIdSelectors,
} from "../../../store/offersForSeller/byId";
import {
  offerForSellerUpdateActions,
  offerForSellerUpdateSelectors,
} from "../../../store/offersForSeller/update";
import {
  offerForSellerUpdateStatusActions,
  offerForSellerUpdateStatusSelectors,
} from "../../../store/offersForSeller/update_status";
import { getOfferForSellerById } from "../../../store/offersForSeller/byId/thunk";
import { updateOfferForSeller } from "../../../store/offersForSeller/update/thunk";
import { updateOfferForSellerStatus } from "../../../store/offersForSeller/update_status/thunk";
import { getApplicationForSellerById } from "../../../store/applicationsForSeller/byId/thunk";
import {
  ApplicationForSellerByIdActions,
  applicationForSellerByIdSelectors,
} from "../../../store/applicationsForSeller/byId";

export const OfferForSeller = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isEditing, setIsEditing] = useState(false);

  const { user } = useAppSelector(userSelectors.getState);
  const { offerForSeller } = useAppSelector(
    offerForSellerByIdSelectors.getState
  );
  const { isLoading: updateIsLoading, error: updateError } = useAppSelector(
    offerForSellerUpdateSelectors.getState
  );

  const { isLoading: statusIsLoading, error: statusError } = useAppSelector(
    offerForSellerUpdateStatusSelectors.getState
  );
  const { accounts } = useAppSelector(accountsByCabinetIdSelectors.getState);
  const { application } = useAppSelector(
    applicationForSellerByIdSelectors.getState
  );

  const { offer_id } = useParams<{ offer_id: string }>();

  const statusOptions: MenuProps["items"] = useMemo(
    () =>
      offerForSeller
        ? (offerUpdateForSellerStatusOptions[offerForSeller?.status_id] || []).map(
            (status) => {
              const statusData = offerStatusIdMapper[status];
              return {
                key: status,
                label: (
                  <Tag color={statusData?.color}>{statusData?.shortName}</Tag>
                ),
              };
            }
          )
        : [],
    [offerForSeller]
  );

  const canUpdate = useMemo(() => {
    const foundEntity = user?.entities?.find(
      (entity) =>
        offerForSeller?.seller_entity?.entity_id === entity?.entity?.entity_id
    );
    return (
      (foundEntity?.role_id && foundEntity?.role_id >= 2) ||
      (foundEntity?.entity?.cabinet_id === user?.cabinet?.cabinet_id &&
        user?.cabinet_role_id === 2)
    );
  }, [offerForSeller, user]);

  const canChangeStatus = useMemo(
    () => !!statusOptions?.length && canUpdate,
    [canUpdate, statusOptions]
  );

  const statusData = useMemo(
    () =>
      offerForSeller?.status_id
        ? offerStatusIdMapper[offerForSeller?.status_id]
        : undefined,
    [offerForSeller]
  );

  const getOffer = useCallback(() => {
    dispatch(getOfferForSellerById(Number(offer_id)));
  }, [dispatch, offer_id]);

  const getAccounts = useCallback(() => {
    user?.cabinet &&
      dispatch(getAccountsByCabinetId(user?.cabinet?.cabinet_id));
  }, [dispatch, user?.cabinet]);

  const getApplication = useCallback(
    (application_id: number) => {
      return dispatch(getApplicationForSellerById(application_id));
    },
    [dispatch]
  );

  const onEdit = useCallback(() => {
    setIsEditing(true);
  }, []);
  const onCancelEdit = useCallback(() => {
    setIsEditing(false);
  }, []);

  const onUpdate = useCallback(
    (values: TOfferForSellerRequest) => {
      dispatch(updateOfferForSeller(values))
        .unwrap()
        .then(() => {
          onCancelEdit();
          getOffer();
        });
    },
    [dispatch, getOffer, onCancelEdit]
  );

  const onUpdateStatus = useCallback(
    ({ key }: { key: string }) => {
      offerForSeller &&
        dispatch(
          updateOfferForSellerStatus({
            offer_id: offerForSeller?.offer_id,
            new_status_id: Number(key),
          })
        )
          .unwrap()
          .then(() => {
            getOffer();
          });
    },
    [dispatch, getOffer, offerForSeller]
  );

  useEffect(() => {
    if (!user?.cabinet?.is_seller) {
      navigate("/offers");
    }
  }, [navigate, user]);

  useEffect(() => {
    offer_id && getOffer();
  }, [getOffer, offer_id]);

  useEffect(() => {
    offerForSeller && getApplication(offerForSeller?.application_id);
  }, [getApplication, offerForSeller]);

  useEffect(() => {
    !accounts && getAccounts();
  }, [accounts, getAccounts]);

  useEffect(() => {
    return () => {
      dispatch(offerForSellerUpdateStatusActions.clearState());
      dispatch(offerForSellerUpdateActions.clearState());
      dispatch(offerForSellerByIdActions.clearState());
      dispatch(ApplicationForSellerByIdActions.clearState());
    };
  }, [dispatch]);

  return (
    <Wrapper>
      <Typography.Title
        level={3}
        style={{
          marginTop: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          gap: "12px",
        }}
      >
        Предложение #{offerForSeller?.offer_id}
        {statusData ? (
          <Tooltip title={statusData?.description}>
            <EnlargedTag color={statusData?.color}>
              {statusData?.shortName}
            </EnlargedTag>
          </Tooltip>
        ) : (
          offerForSeller?.status_id
        )}
      </Typography.Title>

      {offerForSeller ? (
        <>
          {(canUpdate || canChangeStatus) && (
            <CTABlock>
              {canUpdate && (
                <Button
                  icon={isEditing ? <CloseOutlined /> : <EditOutlined />}
                  onClick={isEditing ? onCancelEdit : onEdit}
                >
                  {isEditing ? "Отмена" : "Редактировать"}
                </Button>
              )}

              {canChangeStatus && !isEditing && (
                <Dropdown
                  menu={{ items: statusOptions, onClick: onUpdateStatus }}
                >
                  <Button
                    loading={statusIsLoading}
                    style={{ marginLeft: "auto" }}
                  >
                    <Space>
                      Изменить статус
                      <DownOutlined />
                    </Space>
                  </Button>
                </Dropdown>
              )}
            </CTABlock>
          )}

          <ErrorAlert error={statusError} />

          {isEditing ? (
            <OfferEditForm
              offer={offerForSeller}
              application={application}
              user={user}
              accounts={accounts}
              onSubmit={onUpdate}
              isLoading={updateIsLoading}
              error={updateError}
            />
          ) : (
            <>
              {application && <ApplicationsTable applications={[application]} forSeller pagination={false}/>}

              <OfferData offer={offerForSeller} forSeller/>
            </>
          )}
        </>
      ) : (
        <Alert message="Данные по предложению отсутствуют" showIcon />
      )}
    </Wrapper>
  );
};
