import { PageableResponse, TOfferForSeller } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const getOffersForSeller = (
  data: TGetOffersForSellerRequest
): RequestPromise<TGetOffersForSellerResponse> => {
  return apiRequest.get(`/offers-for-sellers/get`, {
    params: data,
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TGetOffersForSellerRequest = {
  search?: string;
  size: number;
  page: number;
};

export type TGetOffersForSellerResponse = PageableResponse<TOfferForSeller>;
