import { createAsyncThunk } from "@reduxjs/toolkit";
import { approvementsByUserIdActions } from "./byUserId";
import { applicationUpdateActions } from "../applications/update";

export const clearApprovements = createAsyncThunk<void, void>(
  "approvement/clearApprovements",
  (payload, { dispatch, rejectWithValue }) => {
    dispatch(approvementsByUserIdActions.clearState());
    dispatch(applicationUpdateActions.clearState());
  }
);
