import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { TOfferForBuyerUpdateStatusState } from "./types";

import { RequestError } from "../../../types";
import { TUpdateOfferForBuyerStatusResponse } from "../../../api/offers_for_buyers";

const initialState: TOfferForBuyerUpdateStatusState = {
  isLoading: false,
  error: null,
  offerForBuyerId: undefined,
  new_status_id: undefined,
};

export const OfferForBuyerUpdateStatusSlice = createSlice({
  name: "offerForBuyer/update_status",
  initialState,
  reducers: {
    clearState: () => initialState,
    setOfferForBuyer: (
      state,
      { payload }: PayloadAction<TUpdateOfferForBuyerStatusResponse>
    ) => {
      state.offerForBuyerId = payload?.offer_id;
      state.new_status_id = payload?.new_status_id;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setError: (state, { payload }: PayloadAction<RequestError | null>) => {
      state.error = payload;
    },
  },
});

export const offerForBuyerUpdateStatusActions =
  OfferForBuyerUpdateStatusSlice.actions;
export const offerForBuyerUpdateStatusReducer =
  OfferForBuyerUpdateStatusSlice.reducer;

export * as offerForBuyerUpdateStatusSelectors from "./selectors";
