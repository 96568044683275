import React from "react";
import { CardButtonsContainer, Panel } from "../../common";
import { FileTextOutlined, InboxOutlined, LikeOutlined } from "@ant-design/icons";
import { useAppSelector } from "../../../store/store";
import { userSelectors } from "../../../store/user";

export const HomePage = () => {
  const { user } = useAppSelector(userSelectors.getState);

  return (
    <Panel
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        flex: 1,
        gap: "12px",
      }}
    >
      <CardButtonsContainer
        buttons={[
          ...(user?.cabinet?.is_seller
            ? [
                {
                  to: "/availableapplications",
                  icon: <FileTextOutlined />,
                  title: "Заявки",
                },
                {
                  to: "/myoffers",
                  icon: <InboxOutlined />,
                  title: "Предложения",
                },
              ]
            : [
                {
                  to: "/applications",
                  icon: <FileTextOutlined />,
                  title: "Заявки",
                },
                {
                  to: "/offers",
                  icon: <InboxOutlined />,
                  title: "Предложения",
                },
              ]),
          {
            to: "/approvements",
            icon: <LikeOutlined />,
            title: "Согласования",
          },
        ]}
      />
    </Panel>
  );
};
