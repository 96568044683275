import { createAsyncThunk } from "@reduxjs/toolkit";
import { accountAddActions } from ".";
import * as api from "../../../api/accounts";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const addAccount = createAsyncThunk<
  api.TAddAccountResponse,
  api.TAddAccountRequest
>("account/addAccount", (payload, { dispatch, rejectWithValue }) => {
  dispatch(accountAddActions.setLoading(true));
  dispatch(accountAddActions.setError(null));
  return api
    .addAccount(payload)
    .then(({ data }) => {
      dispatch(accountAddActions.setAccount(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(accountAddActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(accountAddActions.setLoading(false));
    });
});
