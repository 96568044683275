import { TApplicationApprovement } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const getApprovementsByUserId = (
  id: TByUserIdApprovementsRequest
): RequestPromise<TByUserIdApprovementsResponse> => {
  return apiRequest.get(`/approvements/user/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TByUserIdApprovementsRequest = number;

export type TByUserIdApprovementsResponse = TApplicationApprovement[];
