import { TEntity } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const addEntity = (
  data: TAddEntityRequest
): RequestPromise<TAddEntityResponse> => {
  return apiRequest.post(`/entities/add`, data, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TAddEntityRequest = Omit<TEntity, "entity_id">;

export type TAddEntityResponse = TEntity;
