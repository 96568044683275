import { TApplication } from "../../../types";
import { RequestPromise, apiRequest } from "../../../utils/api";
import Cookies from "js-cookie";

export const getApplicationById = (
  id: TGetApplicationByIdRequest
): RequestPromise<TGetApplicationByIdResponse> => {
  return apiRequest.get(`/applications/${id}`, {
    headers: {
      Authorization: "Bearer " + Cookies.get("token"),
    },
  });
};

export type TGetApplicationByIdRequest = number;

export type TGetApplicationByIdResponse = TApplication;
