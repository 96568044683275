import { createAsyncThunk } from "@reduxjs/toolkit";
import { projectAddActions } from ".";
import * as api from "../../../api/project";
import { RequestError } from "../../../types";
import { serializeError } from "serialize-error";

export const addProject = createAsyncThunk<
  api.TAddProjectResponse,
  api.TAddProjectRequest
>("project/addProject", (payload, { dispatch, rejectWithValue }) => {
  dispatch(projectAddActions.setLoading(true));
  dispatch(projectAddActions.setError(null));
  return api
    .addProject(payload)
    .then(({ data }) => {
      dispatch(projectAddActions.setProject(data));
      return data;
    })
    .catch((err: RequestError) => {
      dispatch(projectAddActions.setError(err));
      return rejectWithValue(serializeError(err));
    })
    .finally(() => {
      dispatch(projectAddActions.setLoading(false));
    });
});
